/*
 * @Author: 李劲宏
 * @Date: 2022-11-04 10:43:47
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-08 11:33:56
 * @FilePath: /官网项目/src/axios/product.js
 */
import httpRequest from './index.js'
// 获取产品列表
export const productList = () => {
	return httpRequest({
		url: '/companyserver/api/product/productCategoryList',
		method: 'post',
	})
}
// 根据产品id获取产品详情
export const getProductDetails = (param) => {
	return httpRequest({
		url: '/companyserver/api/product/productQueryById',
		method: 'post',
		data: param
	})
}