<!--
 * @Author: 李劲宏
 * @Date: 2022-10-31 10:43:33
 * @LastEditors: 曹柯
 * @LastEditTime: 2022-11-14 14:10:27
 * @FilePath: \officialwebsite\src\view\about.vue
-->
<template>
  <div class="about-bg">
    <img :src="bannerImg" alt="关于我们" style="width: 100%;">
  </div>
  <div style="min-height:1200px;margin-bottom:40px;">
    <el-row>
      <el-col :span="18" :offset="3" :xs="{span:22,offset:1}">
        <div class="page-lable">
          <span class="page-lable-router" @click="toPath()">首页 </span> >
          <span> 关于我们</span>
        </div>
        <div class="title-content">
          <p class="title-english">COMPANY PROFIE</p>
          <p class="title-chinese">公司简介</p>
        </div>
        <div>
          <el-row :gutter="20">
            <el-col :span="6" :xs="24">
              <div class="about-content-left">
                <p class="about-content-english">ABOUT US</p>
                <p class="company-name-p">
                  成都瑞特数字科技有限责任公司
                </p>
              </div>
            </el-col>
            <el-col :span="18" :xs="24">
              <div class="about-content-right">
                <p class="about-content-describe">
                      成都瑞特数字科技有限责任公司自2005年成立以来，以“应用新技术，促进智能制造技术和智能制造系统的研究”为使命，与原煤炭部最高学府“中国矿业大学”和原铁道部最高学府“西南交通大学”紧密合作，利用数字化、自动化、网络化和智能化为特征的现代新型技术，致力于智能制造和智慧燃气两大业务领域。
                </p>
                <p class="about-content-describe">
                      在智能制造领域，公司一直为政府、企业、教育等领域提供智能制造咨询规划、设计实施、平台建设等智能制造整体解决方案和工程应用的落地实践。其中，智能仓储软件调度（WCS、WMS）、复合式移动机器人（机器视觉、AGV）、网络平台搭建与数据采集（SCADA）、网络协同制造、仿真设计等产品和服务，在西南铝集团、海康威视、中车、绵阳九院、成都巴莫科技、宏华石油、中国民用航空飞行学院、四川工商职业技术学院等企业和高校得到了典型示范应用。
                </p>
                <p class="about-content-describe">
                      在智慧燃气领域，公司一直为全球500强企业Emerson
                  Fisher等公司提供专业、优质的服务，其中包括有燃气管网4S综合管理系统、监视控制与数据采集系统（SCADA）、地理信息系统（GIS）、燃气管网巡线等外勤作业管理系统（GPS巡检与手机作业APP），自动化信息融合系统集成，危险爆炸环境用防爆产品等
                </p>
                <p class="about-content-describe">
                      公司的产品和服务涉及天然气、石化、煤矿、智能化工厂、智慧校园、智能制造等行业，服务范围还拓展到了我国海军、航空、核动力、高铁等高精技术领域。我们的用户已遍及中国四川、广东、江苏、甘肃、内蒙古、新疆等国内二十几个省份，部分产品和服务还出口韩国、印度、泰国、哈萨克斯坦等国家和地区。我们坚信，在我们团队的努力和带动下，定能实现我国“工业生产更安全，人民生活更智能”的美好愿望！
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <div class="introduce-bg">
      <div class="left"></div>
      <div class="center">
        <div class="introduce-content-top">
          <span>专业专注 值得信赖</span>
        </div>
        <div class="introduce-item-box">
          <el-row>
            <el-col :span="6" :xs="12">
              <div class="about-item">
                <img src="../assets/img/about/gongsi.png" alt="成都瑞特" class="about-icon">
                <span class="about-describe-span">成都瑞特成立于</span><br>
                <span class="about-describe-span2">{{info.setUp}}</span>
                <span class="about-describe-span">年 </span>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div class="about-item">
                <img src="../assets/img/about/project.png" alt="成都瑞特" class="about-icon">
                <span class="about-describe-span">项目实施经验</span><br>
                <span class="about-describe-span2">{{info.implementationExperience}}</span>
                <span class="about-describe-span">年 </span>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div class="about-item">
                <img src="../assets/img/about/zhuanli.png" alt="成都瑞特" class="about-icon">
                <span class="about-describe-span">专利和著作权</span><br>
                <span class="about-describe-span2">{{info.patentsCopyrights}}</span>
                <span class="about-describe-span">
                  项
                </span>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div class="about-item" style="border:none">
                <img src="../assets/img/about/kehu.png" alt="成都瑞特" class="about-icon">
                <span class="about-describe-span">长期合作客户</span><br>
                <span class="about-describe-span2">{{info.customer}}</span>
                <span class="about-describe-span"></span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="right">

      </div>

    </div>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:20,offset:2}">
        <div class="title-content">
          <p class="title-english">CORPORATE CULTURE</p>
          <p class="title-chinese">企业文化</p>
        </div>
        <div style="width:100%;min-height:200px;">
          <el-row>
            <el-col :span="6" :xs="12">
              <div class="culture">
                <img src="../assets/img/about/yuanjin.png" alt="成都瑞特">
                <p>企业愿景</p>
                <span>工业生产更安全 人民生活更智能</span>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div class="culture">
                <img src="../assets/img/about/jingsheng.png" alt="成都瑞特">
                <p>企业精神</p>
                <span>自信 自醒 自悟 自修</span>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div class="culture">
                <img src="../assets/img/about/zongzhi.png" alt="成都瑞特">
                <p>企业宗旨</p>
                <span>以人为本 共谋发展</span>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div class="culture">
                <img src="../assets/img/about/jiazhi.png" alt="成都瑞特">
                <p>企业价值观</p>
                <span>价值 共赢 合作 发展</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="culture-bg-box">
          <el-row :gutter="20">
            <el-col :span="12" :xs="24">
              <div class="culture-bg1">
                以科技为先导 以创新求发展
              </div>
            </el-col>
            <el-col :span="12" :xs="24">
              <div class="culture-bg2">
                以质量求生存 以诚信待客户
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="title-content">
          <p class="title-english">QUALIFICATION HONOR</p>
          <p class="title-chinese">荣誉资质</p>
        </div>
        <div class="honor">
          <el-row :gutter="20">
            <el-col :span="6" :xs="8" v-for=" img in translateList " :key="img.id">
              <div class="honor-item">
                <img :src="img.img" :alt="img.name" class="honor-item-img">
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <div class="pagination-box">
      <el-pagination small background layout="prev, pager, next" @current-change="handleCurrentChange"
        :current-page="pageNum" :page-size="pageSize" :total="totalCount" />
    </div>
  </div>
</template>

<script>
  import { getBanner } from '../axios/home.js';
  import { reactive, toRefs, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import * as http from '../axios/about';
  import { routerActive } from '../store/index';
  export default {
    metaInfo: {
      title: '关于我们_企业简介_智慧燃气-数字化工厂-智能仓储-成都瑞特',
      meta: [
        {
          name: 'keyWords',
          content: '智慧燃气建设,智慧燃气服务者'
        },
        {
          name: 'description',
          content: '成都瑞特致力于智能仓储、智慧燃气建设、嵌入式系统与智能设备研制、智能工厂、手机APP开发与信息系统集成等业务。服务范围还拓展到了我国海军、航空、核动力、高铁等高精技术领域。我们即是智慧燃气技术的前进者也是智慧燃气服务者.'
        }
      ]
    },
    setup() {
      const $router = useRouter();
      const aboutData = reactive({
        info: '', // 公司信息
        totalCount: 0,
        pageNum: 1,
        pageSize: 8,
        translateList: [],
        bannerImg:''
      });
      //获取banner图
      onMounted(async () => {
        let res = await getBanner({ id: 2 });
        const { data, code, msg } = res;
        if (code === 200) {
          aboutData.bannerImg = data[0].img;
        } else {
          console.log(msg);
        }
      })
      /**
       * @author: ljh
       * @api: 
       * @description: 获取公司简介信息
       */
      onMounted(async () => {
        let res = await http.aboutInfo();
        const { data, code, msg } = res;
        if (code === 200) {
          aboutData.info = data[0].data[0];
        } else {
          console.log(msg);
        }
      });
      /**
       * 
       * @description: 获取公司资质图片
       */
      const getPageList = async () => {
        let params = {
          pageNum: aboutData.pageNum,
          pageSize: aboutData.pageSize,
        };
        let res = await http.translate(params);
        const { data, code, msg, count } = res;
        if (code === 200) {
          aboutData.totalCount = count;
          aboutData.translateList = data;
        } else {
          console.log(msg)
        }
      };
      onMounted(() => {
        getPageList();
      });
      const handleCurrentChange = (pageNum) => {
        aboutData.pageNum = pageNum;
        getPageList()
      }
      const routerActives = routerActive();
      const toPath = () => {
        routerActives.active = 1;
        sessionStorage.setItem("active", 1);
        $router.push('/index');
      }
      return {
        ...toRefs(aboutData),
        getPageList,
        handleCurrentChange,
        toPath
      }
    }
  }
</script>

<style scoped>
  .about-bg {
    width: 100%;
    height: 380px;
    margin-top: 20px;
  }

  @media screen and (max-width: 1440px) {
    .about-bg {
      height: 314px;
    }
  }

  @media screen and (max-width: 1080px) {
    .about-bg {
      height: 230px;
    }
  }

  @media screen and (max-width: 768px) {
    .about-bg {
      height: 267px;
    }
  }

  @media screen and (max-width: 425px) {
    .about-bg {
      height: 105px;
    }
  }

  .about-content-right {
    width: 100%;
    min-height: 300px;
  }

  .about-content-left {
    width: 100%;
    min-height: 300px;
  }

  @media screen and (max-width: 1080px) {
    .about-content-left {
      width: 100%;
      min-height: 100px;
    }
  }

  .about-content-english {
    font-size: 16px;
    color: #0051af;
  }

  .company-name-p {
    font-size: 33px;
    color: #333333;
  }

  @media screen and (max-width: 1080px) {
    .company-name-p {
      font-size: 24px;
      color: #333333;
    }
  }

  .about-content-describe {
    font-size: 16px;
    color: #333333;
    line-height: 25px;
    text-indent:32px;
  }

  .introduce-bg {
    width: 100%;
    height: 320px;
    background-image: url('../assets/img/about/introduce-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 30px 0;
    padding-top: 56px;
    display: flex;
  }

  @media screen and (max-width: 425px) {
    .introduce-bg {
      background-image: none;
    }
  }

  .introduce-bg .left {
    width: 15%;
  }

  .introduce-bg .center {
    height: 100%;
    flex: 1;
  }

  .introduce-bg .right {
    width: 15%;
  }

  .introduce-content-top {
    height: 30px;
    width: 100%;
    text-align: center;
    line-height: 30px;
    font-size: 24px;
    color: #fff;
  }

  @media screen and (max-width: 425px) {
    .introduce-content-top {
      color: #000;
    }
  }

  .introduce-item-box {
    width: 100%;
    min-height: 200px;
    margin-top: 80px;
  }

  .about-item {
    width: 100%;
    height: 58px;
    border-right: 1px solid #b0b2bf;
    position: relative;
    color: #fff;
    text-align: center;
    margin: 10px 0;
  }

  @media screen and (max-width: 425px) {
    .about-item {
      color: #0051af;
    }
  }

  .about-describe-span {
    font-size: 14px;
  }

  .about-describe-span2 {
    font-size: 36px;
  }

  .about-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 5%;
  }

  @media screen and (max-width: 425px) {
    .about-icon {
      width: 30px;
      height: 30px;
    }

    .about-describe-span2 {
      font-size: 14px;
      font-weight: 600;
    }

    .about-describe-span {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 768px) {
    .about-describe-span2 {
      font-size: 28px;
    }
  }

  .title-content {
    margin: 40px 0;
  }

  .culture {
    width: 100%;
    min-height: 120px;
    text-align: center;
    margin-bottom: 20px;
  }

  .culture img {
    width: 80px;
    height: 80px;
  }

  .culture p {
    font-size: 18px;
    font-weight: 800;
  }

  .culture img {
    font-size: 12px;
    color: #666874;
  }

  .culture-bg-box {
    width: 100%;
    margin: 80px 0;
  }

  .culture-bg1 {
    width: 100%;
    height: 200px;
    background-image: url('../assets/img/about/bg1@2x.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    line-height: 200px;
    font-size: 20px;
    font-weight: 800;
    color: #fff;
    margin: 5px 0;
  }

  .culture-bg2 {
    width: 100%;
    height: 200px;
    background-image: url('../assets/img/about/bg2@2x.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    line-height: 200px;
    font-size: 20px;
    font-weight: 800;
    color: #fff;
    margin: 5px 0;
  }

  .honor-item {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    height: auto;
  }

  .honor-item-img {
    width: 100%;
    height: 100%;
  }

  .pagination-box {
    min-height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
</style>