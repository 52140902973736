/*
 * @Author: 李劲宏
 * @Date: 2022-11-07 11:23:16
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-08 13:42:41
 * @FilePath: /官网项目/src/axios/programme.js
 */
import httpRequest from './index.js'
// 查询方案类型
export const programmeType = () => {
    return httpRequest({
        url: '/companyserver/api/solution/categoryQueryList',
        method: 'post',
    })
}
//根据方案类型id 获取对应解决方案
export const programmeOfID = (params) => {
    return httpRequest({
        url: '/companyserver/api/solution/schemeQueryList',
        method: 'post',
        data:params
    })
}
//查询产品列表
export const productData = (params) => {
    return httpRequest({
        url: '/companyserver/api/solution/productQueryList',
        method: 'post',
        data:params
    })
    
}
// 查询项目案列
export const projectData = (params) => {
    return httpRequest({
        url: '/companyserver/api/solution/caseQueryPage',
        method: 'post',
        data: params
    })
}
// 根据解决方案id获取解决方案详情
export const getProgramDetail = (params) => {
    return httpRequest({
        url: '/companyserver/api/solution/schemeQueryById',
        method: 'post',
        data: params
    })
}
// 根据项目案列id查询项目案列详情
export const getcaseDetail = (params) => {
    return httpRequest({
        url: '/companyserver/api/solution/caseQueryById',
        method: 'post',
        data: params
    })
}