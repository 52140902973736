<!--
 * @Author: 李劲宏
 * @Date: 2022-11-01 11:19:44
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 14:02:50
 * @FilePath: /官网项目/src/view/contact.vue
-->
<template>
  <div class="contect-bg">
    <img :src="bannerImg" alt="联系我们" style="width: 100%;">
  </div>
  <div class="contect">
    <el-row>
      <el-col :span="18" :offset="3" :xs={span:20,offset:1}>
        <div class="page-lable">
          <span class="page-lable-router" @click="toPath">首页 </span> >
          <span> 联系我们</span>
        </div>
        <div class="title-content">
          <p class="title-english">CONTACT US</p>
          <p class="title-chinese">联系我们</p>
        </div>
        <div class="main">
          <el-row :gutter="20">
            <el-col :xs='{span:22,offset:1}' :span="16">
              <div class="company-div">
                <h3>成都瑞特数字科技有限责任公司</h3>
                <span style="color:#333333;">开启您的智能制造时代</span>
              </div>
              <div class="hotline-div">
                <el-row>
                  <el-col :span="12" :xs="24">
                    <div class="icon-content">
                      <div class="left">
                        <img src="../assets/img/contect/hotline.png" alt="服务热线" class="icon-content-img">
                      </div>
                      <div class="right">
                        <span>服务热线</span><br>
                        <span>028-87632737</span>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12" :xs="24">
                    <div class="icon-content">
                      <div class="left">
                        <img src="../assets/img/contect/hezuo.png" alt="合作联系" class="icon-content-img">
                      </div>
                      <div class="right">
                        <span>合作联系</span><br>
                        <span>18702811167</span>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="icon-content" style="background: #edf0f3;">
                <div class="left">
                  <img src="../assets/img/contect/qiyeyouxiang.png" alt="企业邮箱" class="icon-content-img">
                </div>
                <div class="right">
                  <span>企业邮箱</span><br>
                  <span>right@cdright.com</span>
                </div>
              </div>
              <div class="icon-content">
                <div class="left">
                  <img src="../assets/img/contect/address.png" alt="联系地址" class="icon-content-img">
                </div>
                <div class="right">
                  <span>联系地址</span><br>
                  <span>四川省成都市武侯区武科西一路65号优博中心D座801-802室</span>
                </div>
              </div>
            </el-col>
            <el-col :xs='{span:22,offset:1}' :span="8">
              <div class="map">
                <img src="../assets/img/contect/map.png" style="width: 100%;">
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
  <div class="message-bg">
    <div class="message-box">
      <el-row>
        <el-col :span="8" :xs="24">
          <div class="left">
            <span class="left-english-span">ONLINE MESSAGE</span><br>
            <span class="left-chinses-span">在线留言</span>
            <img src="../assets/img/contect/emil.png" class="emil-img" alt="邮件">
          </div>
        </el-col>
        <el-col :span="16" :xs="24">
          <div class="right">
            <div class="message-box-form">
              <div class="message-text">
                <span>我们会在第一时间与您取得联系，为您提供系统及产品服务咨询，感谢您的关注</span>
              </div>
              <div class="input-box">
                <el-form ref="ruleFormRef" :model="ruleForm" status-icon :rules="rules" class="demo-ruleForm">
                  <el-row :gutter='10'>
                    <el-col :span="12">
                      <el-form-item prop="name">
                        <el-input v-model="ruleForm.name" placeholder="姓名" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item prop="phone">
                        <el-input v-model="ruleForm.phone" placeholder="电话" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item prop="company">
                        <el-input v-model="ruleForm.company" placeholder="公司" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item prop="email">
                        <el-input v-model="ruleForm.email" placeholder="邮箱" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item prop="content">
                        <el-input type="textarea" v-model="ruleForm.content" placeholder="留言" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <div class="form-btn-box">
                  <div class="btn-div" @click="send()">
                    发送
                  </div>
                </div>
              </div>

            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { getBanner } from '../axios/home.js';
  import { useRouter } from 'vue-router';
  import { routerActive } from '../store/index';
  import { leaveWord } from '../axios/contact'
  import { reactive, toRefs, ref, onMounted } from 'vue';
  import { ElMessage } from 'element-plus'
  export default {
    metaInfo: {
      title: '联系我们_智慧燃气-数字化工厂-智能仓储-成都瑞特',
      meta: [   // set meta
        {
          name: 'keyWords',
          content: '智能仓储多少钱,数字化工厂概念'
        },
        {
          name: 'description',
          content: '成都瑞特利用数字化,自动化,网络化和智能化为特征的现代新型技术,致力于智慧燃气,智能仓储,非标自动化, 智慧燃气平台等业务, 智能仓储搭建多少钱，以及数字化工厂是什么搭建前景等，欢迎咨询。'
        }
      ]
    },
    setup() {
      const $router = useRouter();
      const data = reactive({
        ruleForm: {
          name: '',
          phone: '',
          company: '',
          email: '',
          content: '',
        },
        bannerImg: ''
      });
      //获取banne图
      onMounted(async () => {
        let res = await getBanner({ id: 7 });
        if (res.code === 200) {
          data.bannerImg = res.data[0].img;
        } else {
          console.log(res.msg);
        }
      })
      // 表单邮箱格式校验
      const regEmail = (rule, value, callback) => {
        const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
        if (!value) {
          return callback(new Error('请输入您的邮箱地址。'))
        } else if (!reg.test(value)) {
          return callback(new Error('邮箱格式有误，请重新输入。'))
        }
        callback()
      };
      const rules = reactive({
        name: [{ required: true, message: '请填写您的名字', trigger: 'blur' }],
        phone: [{ required: true, message: '请留下您的联系方式', trigger: 'blur' }],
        company: [{ required: true, message: '请留下您的公司名称', trigger: 'blur' }],
        email: [{ required: true, validator: regEmail, trigger: 'blur' }],
        content: [{ required: true, message: '请留下您的宝贵意见', trigger: 'blur' }]
      });
      const ruleFormRef = ref(null);

      const submitForm = async () => {
        let res = await leaveWord(data.ruleForm);
        const { msg, code } = res;
        if (code === 200) {
          Object.keys(data.ruleForm).forEach(k => {
            data.ruleForm[k] = '';
          });
          ElMessage.success('感谢您的留言，我们将尽快回复您！')
        } else {
          ElMessage.error(msg)
        }
      };
      // 发送留言
      const send = () => {
        // console.log(ruleFormRef.value.validate, 444)
        ruleFormRef.value.validate(async (valid) => {
          if (valid) {
            console.log(valid, 10000);
            await submitForm()
          } else {
            console.log('error submit!!')
            return false
          }
        });
      };
      const routerActives = routerActive();
      const toPath = () => {
        routerActives.active = 1;
        sessionStorage.setItem("active", 1);
        $router.push('/index');
      }
      return {
        ...toRefs(data),
        send,
        toPath,
        rules,
        ruleFormRef

      }
    },

  }
</script>

<style scoped>
  .contect-bg {
    width: 100%;
    height: 380px;
    margin-top: 20px;
  }

  @media screen and (max-width: 1440px) {
    .contect-bg {
      height: 300px;
    }
  }

  @media screen and (max-width: 1080px) {
    .contect-bg {
      height: 250px;
    }
  }

  @media screen and (max-width: 768px) {
    .contect-bg {
      height: 200px;
    }
  }

  @media screen and (max-width: 425px) {
    .contect-bg {
      height: 150px;
    }
  }

  .contect {
    margin-top: 20px;
    height: auto;
  }

  .main {
    width: 100%;
    min-height: 443px;
    margin-top: 40px;
  }

  .message-bg {
    width: 100%;
    height: 664px;
    background-image: url('../assets/img/contect/massage.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    /* 水平垂直居中 */
    justify-content: center;
    align-items: center;
  }

  .message-box {
    width: 1100px;
    height: 424px;
  }

  .message-box .left {
    width: 100%;
    height: 424px;
    background: #108bed;
    color: #fff;
    position: relative;
  }

  .left-english-span {
    font-size: 30px;
    margin: 0;
    position: relative;
    top: 10%;
    left: 5%;
  }

  .left-chinses-span {
    font-size: 25px;
    /* font-weight: 600; */
    margin: 0;
    position: relative;
    top: 12%;
    left: 5%;
  }

  @media screen and (max-width: 768px) {
    .message-box .left {
      display: none;
    }

    .message-bg {
      background: #fff;
    }
  }

  .message-box .right {
    width: 100%;
    height: 424px;
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .message-box-form {
    display: inline-block;
    width: 648px;
    height: 324px;
  }

  .message-text {
    width: 100%;
    min-height: 40px;
    line-height: 40px;
    color: #108bed;
    font-size: 14px;
  }

  @media screen and (max-width: 425px) {
    .message-text {
      font-size: 12px;
    }

    .message-box-form {
      width: 357px;
      height: 324px;
    }
  }

  .input-box {
    width: 100%;
    height: 240px;
  }

  .form-btn-box {
    width: 100%;
    height: 44px;
    text-align: center;
  }

  .btn-div {
    width: 180px;
    height: 44px;
    display: inline-block;
    background: #108bed;
    color: #fff;
    font-size: 18px;
    line-height: 44px;
    cursor: pointer;
  }

  .map {
    width: 100%;
    min-height: 350px;
    /* background-image: url('../assets/img/contect/map.png');
    background-size: 100% 100%;
    background-repeat: no-repeat; */
    display: inline-block;
  }

  .company-div {
    width: calc(100% - 20px);
    height: 100px;
    padding: 5px 0 0 20px;
    background: #edf0f3;
  }

  .hotline-div {
    width: 100%;
    min-height: 80px;
  }

  .icon-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
  }

  .icon-content-img {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    .icon-content {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 1080px) {
    .icon-content {
      font-size: 14px;
    }
  }

  .icon-content .left {
    width: 80px;
    line-height: 110px;
    text-align: center;
  }

  .icon-content .right {
    flex: 1;
    color: #333333;
    line-height: 25px;
    position: relative;
    top: 15px;
  }

  .emil-img {
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: 5%;
    right: 5%;
  }
</style>