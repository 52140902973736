/*
 * @Author: 李劲宏
 * @Date: 2022-11-04 13:57:58
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-08 10:06:48
 * @FilePath: /官网项目/src/axios/recruit.js
 */

import httpRequest from './index.js'
// 获取招聘分页列表
export const recruitList = param => {
	return httpRequest({
		url: '/companyserver/api/personnel/position/publish/getWebPageByCondition',
		method: 'post',
		data: param,
	})
}
//获取所有职位下拉列表
export const positionSelects = param => {
	return httpRequest({
		url: '/companyserver/api/personnel/position/publish/getWebListByCondition',
		method: 'post',
		data: param,
	})
}
//获取所有工作列表
export const jobSelects = param => {
	return httpRequest({
		url: '/companyserver/api/personnel/position/publish/getWebExperienceListByCondition',
		method: 'post',
		data: param,
	})
}
//获取职位详情
export const recruitDetailData = param => {
	return httpRequest({
		url: '/companyserver/api/personnel/position/publish/getWebPersonnelPositionPublishDetail',
		method: 'post',
		data: param,
	})
}