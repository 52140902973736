<!--
 * @Author: 李劲宏
 * @Date: 2022-11-01 14:15:13
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 13:58:41
 * @FilePath: /官网项目/src/view/recruitDetail.vue
-->
<template>
  <div class="recruit-bg">
    <img :src="bannerImg" alt="人才招聘" style="width: 100%;">
  </div>
  <div class="contect">
    <el-row :gutter="20">
      <el-col :xs="{span:22,offset:1}" :sm="{span:20,offset:2}" :xl="{span:14,offset:5}" :lg="{span:14,offset:5}">
        <!-- <div class="page-lable">首页 > 人才招聘 > 招聘详情</div> -->
        <div class="page-lable">
          <span class="page-lable-router" @click="toPath('/index',1)">首页 </span> >
          <span class="page-lable-router" @click="toPath('/recruit',6)">人才招聘 </span> >
          <span> 招聘详情</span>
        </div>
        <div class="recruit-main">
          <div class="left"></div>
          <div class="center">
            <div class="center-top">
              <h3>{{content.webPersonnelPositionName}}</h3>
              <span>{{content.location}}</span>
              <span>{{content.experience}}</span>
              <p>发布时间 : {{content.createTime}}</p>
            </div>
            <div class="center-bottom" v-html="content.requirement">

            </div>
          </div>
          <div class="right">
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
  import { getBanner } from '../axios/home.js';
  import { decrypt } from '../utils/AES.js';
  import * as http from '../axios/recruit';
  import { reactive, toRefs, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { routerActive } from '../store/index';
  export default {
    metaInfo: {
      title: '人才招聘_智慧燃气-数字化工厂-智能仓储-成都瑞特',
      meta: [   // set meta
        {
          name: 'keyWords',
          content: '智能工厂mes管理,智能工厂调度'
        },
        {
          name: 'description',
          content: '成都瑞特利用数字化,自动化,网络化和智能化为特征的现代新型技术,致力于智慧燃气,智能仓储,非标自动化, 智慧燃气平台等业务, 公司进行智能工厂mes管理和智能工厂调度，让用户获得优质的服务。'
        }
      ]
    },
    setup() {
      const $router = useRouter();
      const recruitData = reactive({
        content: '',
        bannerImg:''
      });
      onMounted(async () => {
        let res = await getBanner({ id: 6 });
        if (res.code === 200) {
          recruitData.bannerImg = res.data[0].img;
        } else {
          console.log(res.msg);
        }
      });
      onMounted(async () => {
        let _id = decrypt($router.currentRoute.value.query.id);
        let res = await http.recruitDetailData({ id: _id });
        const { code, data, msg } = res;
        if (code === 200) {
          recruitData.content = data;
        } else {
          console.log(msg);
        }
      });
      const routerActives = routerActive();
      const toPath = (path, active) => {
        routerActives.active = active;
        sessionStorage.setItem("active", active);
        $router.push(path);
      }
      return {
        ...toRefs(recruitData),
        toPath
      }
    }
  }
</script>

<style scoped>
  .recruit-bg {
    width: 100%;
    height: 380px;
    margin-top: 20px;
  }

  @media screen and (max-width: 1440px) {
    .recruit-bg {
      height: 314px;
    }
  }

  @media screen and (max-width: 1080px) {
    .recruit-bg {
      height: 230px;
    }
  }

  @media screen and (max-width: 768px) {
    .recruit-bg {
      height: 167px;
    }
  }

  @media screen and (max-width: 425px) {
    .recruit-bg {
      height: 105px;
    }
  }

  .contect {
    margin-top: 80px;
    /* min-height: 1200px; */
    overflow: hidden;
  }

  .recruit-main {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
  }

  .recruit-main .left {
    width: 10px;
  }

  .recruit-main .center {
    width: 100%;
    flex: 1;
  }

  .recruit-main .right {
    width: 10px;
  }

  .center-top {
    width: 100%;
    height: 130px;
  }

  .center-bottom {
    width: 100%;
    min-height: 700px;
    /* border: 1px solid red; */
  }
</style>