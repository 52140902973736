<!--
 * @Author: 李劲宏
 * @Date: 2022-03-11 23:35:36
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 09:50:36
 * @FilePath: /官网项目/src/App.vue
-->

<template>
  <div>
    <div class="top-icon" @click="toTop"></div>
    <el-container>
      <el-header style="margin:0;padding: 0;">
        <Header />
      </el-header>
      <el-main style="margin:0;padding: 0;">
        <div>
          <router-view></router-view>
        </div>
      </el-main>
      <el-footer style="margin:0;padding: 0;">
        <Bottom />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
  import Header from '@/components/header.vue';
  import Bottom from '@/components/bottom.vue';
  import { onMounted } from 'vue'
  export default {
    components: {
      Header,
      Bottom
    },
    setup() {
      const toTop = () => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      onMounted(() => {
        document.dispatchEvent(new Event('render-event'))
      })
      return {
        toTop
      }
    }
  }
</script>

<style>
  .top-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: fixed;
    bottom: 5%;
    right: 1%;
    z-index: 10000;
    background-image: url('./assets/img/home/to-top.png');
    background-size: 100% 100%;
    cursor: pointer;
  }
</style>