/*
 * @Author: 李劲宏
 * @Date: 2022-11-03 16:34:49
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-09 09:32:07
 * @FilePath: /官网项目/src/axios/contact.js
 */
import httpRequest from './index.js'
export const leaveWord = param => {
    return httpRequest({
		url: '/companyserver/api/contactUs/addWebContactUs',
		method: 'post',
		data: param,
	})
}