<!--
 * @Author: 李劲宏
 * @Date: 2022-11-01 14:32:31
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 13:42:10
 * @FilePath: /官网项目/src/view/productDetail.vue
-->
<template>
  <div class="product-bg">
    <img :src="bannerImg" alt="产品中心" style="width: 100%;">
  </div>
  <div class="product">
    <el-row>
      <el-col :xs="{span:22,offset:1}" :sm="{span:18,offset:3}" :xl="{span:16,offset:4}" :lg="{span:16,offset:4}">
        <div class="page-lable">
          <span class="page-lable-router" @click="toPath('/index',1)">首页 </span> >
          <span class="page-lable-router" @click="toPath('/product',4)">产品中心 </span> >
          <span> 案例详情</span>
        </div>
        <div class="product-detail-box">
          <div v-html='contentText'>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { getBanner } from '../axios/home.js';
  import { getProductDetails } from '../axios/product.js';
  import { decrypt } from '../utils/AES.js'
  import { ref, onMounted} from 'vue';
  import { useRouter ,onBeforeRouteUpdate} from 'vue-router';
  import { routerActive } from '../store/index';
  export default {
    metaInfo: {
      title: '产品中心_智慧燃气-数字化工厂-智能仓储-成都瑞特',
      meta: [   // set meta
        {
          name: 'keyWords',
          content: '智能仓储解决方案企业,智能工厂信息化建设,智慧燃气建设,智慧燃气服务者'
        },
        {
          name: 'description',
          content: '成都瑞特利用数字化,自动化,网络化和智能化为特征的现代新型技术,致力于智慧燃气,智能仓储,非标自动化,数字化工厂等业务,为企业提供智能仓储解决方案企业同时也为智能工厂信息化建设提供了贡献。'
        }
      ]
    },
    setup() {
      const $router = useRouter();
      const rouerId = decrypt($router.currentRoute.value.query.id);
      const routerActives = routerActive();
      //获取banner图
      const bannerImg = ref('');
      onMounted(async () => {
        let res = await getBanner({ id: 4 });
        if (res.code === 200) {
          bannerImg.value = res.data[0].img;
        } else {
          console.log(res.msg);
        }
      })
      /**
       * @describe 根据产品id 获取产品详情
      */
      const contentText = ref();
      const getProductDetailFn = async (id) => {
        let res = await getProductDetails({ id: id });
        const { data, code, msg } = res;
        console.log(res.data)
        if (code === 200) {
          contentText.value = data.content;
        } else {
          console.log(msg);
        }
      }
      onMounted(() => {
        getProductDetailFn(rouerId)
      });
      const toPath = (path, active) => {
        routerActives.active = active;
        sessionStorage.setItem("active", active);
        $router.push(path);
      }
      onBeforeRouteUpdate(async(to) => {
        let _queryId= decrypt(to.query.id)
        getProductDetailFn(_queryId)
      })
      return {
        toPath,
        bannerImg,
        contentText,
      }
    }
  }
</script>

<style scoped>
  .product-bg {
    width: 100%;
    height: 380px;
    margin-top: 20px;
  }

  @media screen and (max-width: 1440px) {
    .product-bg {
      height: 314px;
    }
  }

  @media screen and (max-width: 1080px) {
    .product-bg {
      height: 230px;
    }
  }

  @media screen and (max-width: 768px) {
    .product-bg {
      height: 167px;
    }
  }

  @media screen and (max-width: 425px) {
    .product-bg {
      height: 105px;
    }
  }

  .product {
    margin-top: 20px;
    height: auto;
  }

  .product-detail-box {
    width: 100%;
    margin-bottom: 30px;
  }

  .product-detail-box img {
    width: 100%;
    height: 100%;
  }
</style>