<!--
 * @Author: 李劲宏
 * @Date: 2022-11-01 14:33:11
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 14:27:42
 * @FilePath: /官网项目/src/view/caseDetail.vue
-->
<template>
  <div class="programme-bg">
    <img :src="bannerImg" alt="解决方案" style="width: 100%;">
  </div>
  <div>
    <el-row>
      <el-col :span="18" :offset="3" :xs="{span:20,offset:2}">
        <div class="page-lable">
          <span class="page-lable-router" @click="toPath('/index',1)">首页 </span> >
          <span class="page-lable-router" @click="toPath('/programme',3)">解决方案 </span> >
          <span class="page-lable-router" @click="goback()">方案详情 </span> >
          <span> 案例详情</span>
        </div>
        <div class="pro-detail-box">
          <!-- <img :src="src" alt="解决方案"> -->
          <div v-html="contentText">

          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import { getBanner } from '../axios/home.js';
  import { getcaseDetail } from '../axios/programme.js';
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { decrypt } from '../utils/AES.js';
  import { routerActive } from '../store/index';
  export default {
    metaInfo: {
      title: '解决方案_智慧燃气-数字化工厂-智能仓储-成都瑞特',
      meta: [   // set meta
        {
          name: 'keyWords',
          content: '智慧燃气市场规模,智慧燃气行业平台'
        },
        {
          name: 'description',
          content: '公司为了适应市场发展，提高自身技术竞争力，本着优势互补、合作共赢的原则，在智慧燃气行业平台拥有多位资深技术专家，如今在智慧燃气市场规模非常壮大， 以西南交大智能机电所为技术后盾，核心技术团队19人：高级职称8人，中级职称11人，获得博士学位者7人，硕士学位12人，另有兼职在读硕士生30多人，博士研究'
        }
      ]
    },
    setup() {
      const $router = useRouter();
      let routerId = decrypt($router.currentRoute.value.query.id);
      console.log(routerId);
      const routerActives = routerActive();
      //获取banner图
      const bannerImg = ref('');
      onMounted(async () => {
        let res = await getBanner({ id: 3 });
        if (res.code === 200) {
          bannerImg.value = res.data[0].img;
        } else {
          console.log(res.msg);
        }
      })
      /**
       * @description 获取项目案列详情 
       */
      const contentText = ref('');
      onMounted(async () => {
        const res = await getcaseDetail({ id: routerId });
        const { data, code, msg } = res;
        console.log(data,555555);
        if (code === 200) {
         contentText.value = data.content;
         console.log(data.content,10000000);
        } else {
          console.log(msg)
        }
      });
      const toPath = (path, active) => {
        routerActives.active = active;
        sessionStorage.setItem("active", active);
        $router.push(path);
      }
      const goback = () => {
        $router.go(-1);
      }
      return {
        toPath,
        goback,
        bannerImg,
        contentText
      }
    }
  }
</script>

<style scoped>
  .programme-bg {
    width: 100%;
    height: 380px;
    margin-top: 20px;
  }

  @media screen and (max-width: 1440px) {
    .programme-bg {
      height: 314px;
    }
  }

  @media screen and (max-width: 1080px) {
    .programme-bg {
      height: 230px;
    }
  }

  @media screen and (max-width: 768px) {
    .programme-bg {
      height: 167px;
    }
  }

  @media screen and (max-width: 425px) {
    .programme-bg {
      height: 105px;
    }
  }

  .pro-detail-box {
    width: 100%;
    min-height: 400px;
    margin-bottom: 40px;
  }

  .pro-detail-box img {
    width: 100%;
    height: auto;
  }

  .discribe-content {
    width: 100%;
    min-height: 400px;
  }

  .discribe-text-box {
    color: #333333;
    font-size: 14px;
    min-height: 200px;
  }

  .img-box {
    width: 100%;
    height: 400px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .img-box .img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 425px) {
    .img-box {
      height: 284px;
    }
  }

  .programme-name {
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-size: 30px;
    font-weight: bold;
  }

  @media screen and (max-width: 768px) {
    .programme-name {
      font-size: 24px;
    }
  }

  .programme-name span {
    position: relative;
    left: 15px;
  }

  .pagination-box {
    min-height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .product-introduce {
    height: 30px;
    overflow: hidden;
    margin-bottom: 20px;
    font-size: 12px;
  }
</style>