<!--
 * @Author: 李劲宏
 * @Date: 2022-10-31 11:36:38
 * @LastEditors: 曹柯
 * @LastEditTime: 2022-11-14 16:08:03
 * @FilePath: \officialwebsite\src\components\bottom.vue
-->
<template>
  <div class="bottom-content">
    <div style="padding-top:40px">
      <el-row>
        <el-col :span="4" :offset="4" :xs="{span:22,offset:2}">
          <div class="left">
            <p style="font-weight:bold;font-size: 14px;">服务热线</p>
            <p class="tell-p">028-87632737</p>
            <!-- <p style="font-weight:bold;font-size: 14px;">合作联系</p>
            <p>经理：188 8888 8888</p> -->
            <div class="erweima"></div>
            <p>关注微信公众号</p>
          </div>
        </el-col>
        <el-col :span="10" :xs="{span:20,offset:2}">
          <div class="details">
            <div class="details-item">
              <p class="details-item-top-p">业务领域</p>
              <p class="details-item-route-p" @click="toPath('/programme',3)">智能制造</p>
              <p class="details-item-route-p" @click="toPath('/programme',4)">智慧燃气</p>
            </div>
            <div class="details-item">
              <p class="details-item-top-p">新闻中心</p>
              <p class="details-item-route-p" @click="toPath('/new',11)">企业新闻</p>
              <p class="details-item-route-p" @click="toPath('/new',12)">行业新闻</p>
            </div>
            <div class="details-item">
              <p class="details-item-top-p">更多内容</p>
              <p class="details-item-route-p" @click="toPath('/product')">产品中心</p>
              <p class="details-item-route-p" @click="toPath('/about')">关于我们</p>
              <p class="details-item-route-p" @click="toPath('/recruit')">人才招聘</p>
              <p class="details-item-route-p" @click="toPath('/contact')">联系我们</p>
            </div>
            <!-- <div class="details-item">
              <p class="details-item-top-p">法律声明</p>
              <p class="details-item-route-p">服务承诺</p>
              <p class="details-item-route-p">隐私保护</p>
              <p class="details-item-route-p">法律声明</p>
            </div> -->
            <div class="details-item">
              <p class="details-item-top-p">技术支持</p>
              <p class="details-item-route-p" @click="toPath('/contact')">意见反馈及建议</p>
              <!-- <p class="details-item-route-p">常见问题解答</p>
              <p class="details-item-route-p">资料下载</p> -->
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="beian-box">
      <span>Copyright &copy; 2005-2021 成都瑞特数字科技有限责任公司 版权所有 </span>
      <a href="https://beian.miit.gov.cn" class="beian-a" target="_blank">蜀ICP备15007715号-1</a>
    </div>
  </div>
</template>

<script>
  import { useRouter } from 'vue-router';
  export default {
    setup() {
      const $router = useRouter();
      const toPath = (path, type) => {
        $router.push({
          path: path,
          query: {
            type: type || undefined
          }
        })
      }
      return {
        toPath
      }

    }
  }
</script>

<style scoped>
  .bottom-content {
    width: 100%;
    min-height: 491px;
    background: #192839;
  }

  .left {
    width: 100%;
    color: #fff;
    font-size: 14px;
  }

  .tell-p {
    font-size: 24px;
    position: relative;
    top: -10px;
  }

  .details {
    width: 100%;
    min-height: 350px;
    display: flex;
  }

  .erweima {
    width: 84px;
    height: 84px;
    background-image: url('../assets/img/home/erweima.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .details-item {
    flex: 1;
    text-align: center;
    color: #b0b2bf;
  }

  .details-item-top-p {
    font-size: 18px;
  }

  @media screen and (max-width: 425px) {
    .details-item-top-p {
      font-size: 16px;
    }
  }

  .details-item-route-p {
    font-size: 14px;
    cursor: pointer;
  }
  .details-item-route-p:hover{
    color: #fff;
  }

  @media screen and (max-width: 425px) {
    .details-item-route-p {
      font-size: 12px;
    }
  }

  .details-item-route-p :hover {
    color: #fff;
    background: red;
  }

  .beian-box {
    width: 100%;
    min-height: 91px;
    text-align: center;
    line-height: 91px;
    color: #b0b2bf;
    font-size: 14px;
    border-top: 1px solid #2C3744;
  }

  .beian-a {
    color: #b0b2bf;
    text-decoration: none;
  }

  a:hover {
    color: #fff;
  }
</style>