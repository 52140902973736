<!--
 * @Author: 李劲宏
 * @Date: 2022-11-01 14:33:11
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 13:45:19
 * @FilePath: /官网项目/src/view/programmeDetail.vue
-->
<template>
  <div class="programme-bg">
    <img :src="bannerImg" alt="解决方案" style="width: 100%;">
  </div>
  <div style="min-height:1500px">
    <el-row>
      <el-col :xs="{span:22,offset:1}" :sm="{span:18,offset:3}" :xl="{span:16,offset:4}" :lg="{span:16,offset:4}">
        <div class="page-lable">
          <span class="page-lable-router" @click="toPath('/index',1)">首页 </span> >
          <span class="page-lable-router" @click="toPath('/programme',3)">解决方案 </span> >
          <span> 方案详情</span>
        </div>
        <div class="pro-detail-box">
          <div v-html="contentText" class="procontent"></div>
        </div>
        <div class="programme-name">
          <div class="diamond">
          </div>
          <span>相关产品</span>
        </div>
        <div>
          <div class="produc-item">
            <el-row :gutter="20">
              <el-col :span="8" :xs="24" v-for="p in productList " :key="p.id">
                <div class="product-item-content">
                  <div class="product-item-img">
                    <img :src="p.coverImg" style="width:100%;height: 100%;">
                  </div>
                  <div class="product-item-name" @click="toDetailPath(p.id,'/productdetail')">
                    <span class="product-item-span">{{p.name}}</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="programme-name">
          <div class="diamond">
          </div>
          <span>项目案列</span>
        </div>
        <div class="produc-item">
          <el-row :gutter="20">
            <el-col :span="8" :xs="24" v-for="pro in projectList " :key="pro.id">
              <div class="product-item-content">
                <div class="product-item-img">
                  <img :src="pro.coverImg" style="width:100%;height: 100%;">
                </div>
                <div class="case-item-box" @click="toDetailPath(pro.id,'/casedetail')">
                  <div class="case-info-div">
                    <div class="case-title">
                      <span>{{pro.name}}</span>
                    </div>
                    <div class="introduce-span">
                      <span>{{pro.introduce}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="pagination-box">
          <el-pagination small background layout="prev, pager, next" @current-change="handleCurrentChange"
            :current-page="pageNum" :page-size="pageSize" :total="totalCount" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import { getBanner } from '../axios/home.js';
  import { decrypt, encrypt } from '../utils/AES.js';
  import { onMounted, reactive, toRefs } from 'vue';
  import { useRouter, onBeforeRouteUpdate } from 'vue-router';
  import * as http from '../axios/programme';
  import { routerActive } from '../store/index';
  export default {
    metaInfo: {
      title: '解决方案_智慧燃气-数字化工厂-智能仓储-成都瑞特',
      meta: [   // set meta
        {
          name: 'keyWords',
          content: '智慧燃气市场规模,智慧燃气行业平台'
        },
        {
          name: 'description',
          content: '公司为了适应市场发展，提高自身技术竞争力，本着优势互补、合作共赢的原则，在智慧燃气行业平台拥有多位资深技术专家，如今在智慧燃气市场规模非常壮大， 以西南交大智能机电所为技术后盾，核心技术团队19人：高级职称8人，中级职称11人，获得博士学位者7人，硕士学位12人，另有兼职在读硕士生30多人，博士研究'
        }
      ]
    },
    setup() {
      const $router = useRouter();
      let routerId = decrypt($router.currentRoute.value.query.id);
      const proDetaData = reactive({
        src: '',
        productList: [], // 相关产品
        pageNum: 1,
        pageSize: 6,
        projectList: [],
        totalCount: 100,
        bannerImg: '',
        contentText: '',
      });
      //获取banner图
      onMounted(async () => {
        let res = await getBanner({ id: 3 });
        if (res.code === 200) {
          proDetaData.bannerImg = res.data[0].img;
        } else {
          console.log(res.msg);
        }
      })
      /**
       * @description 获取方案详情 
       */
      let getProgramDetail = async (id) => {
        const res = await http.getProgramDetail({ id: id });
        const { data, code, msg } = res;
        if (code === 200) {
          proDetaData.contentText = data.content;
        } else {
          console.log(msg)
        }
      }
      /**
       * @description 初始化获取产品列表
       */
      let getProductData = async (id) => {
        const res = await http.productData({ schemeId: id });
        const { data, code, msg } = res;
        if (code === 200) {
          proDetaData.productList = data;
        } else {
          console.log(msg)
        }
      }
      /**
       * @description 获取项目案例列表
       */
      const getProjectData = async (id) => {
        let params = {
          pageNum: proDetaData.pageNum,
          pageSize: proDetaData.pageSize,
          schemeId: id
        };
        const res = await http.projectData(params);
        const { data, code, msg, count } = res;
        if (code === 200) {
          proDetaData.totalCount = count;
          proDetaData.projectList = data;
        } else {
          console.log(msg)
        }
      };
      // 初始化执行项目案例请求
      onMounted(() => {
        getProgramDetail(routerId);
        getProductData(routerId);
        getProjectData(routerId);
      })
      //分页查询
      const handleCurrentChange = (pageNum) => {
        proDetaData.pageNum = pageNum;
        getProjectData();
      }
      //路由参数变化重新发起请求
      onBeforeRouteUpdate(async (to) => {
        let _queryId = decrypt(to.query.id);
        getProgramDetail(_queryId);
        getProductData(_queryId);
        getProjectData(_queryId);
      })
      /**
       * @description 跳转对应详情页面
       */
      const toDetailPath = (id, path) => {
        routerActives.active = 4;
        sessionStorage.setItem("active", 4);
        $router.push({
          path: path,
          query: { id: encrypt(id) }
        })
      }
      const routerActives = routerActive();
      const toPath = (path, active) => {
        routerActives.active = active;
        sessionStorage.setItem("active", active);
        $router.push(path);
      }
      return {
        ...toRefs(proDetaData),
        handleCurrentChange,
        toDetailPath,
        toPath
      }
    }
  }
</script>

<style scoped>
  .programme-bg {
    width: 100%;
    height: 380px;
    margin-top: 20px;
  }

  @media screen and (max-width: 1440px) {
    .programme-bg {
      height: 314px;
    }
  }

  @media screen and (max-width: 1080px) {
    .programme-bg {
      height: 230px;
    }
  }

  @media screen and (max-width: 768px) {
    .programme-bg {
      height: 167px;
    }
  }

  @media screen and (max-width: 425px) {
    .programme-bg {
      height: 105px;
    }
  }

  .pro-detail-box {
    max-width: 100%;
    min-height: 400px;
    margin-bottom: 40px;
  }

  .pro-detail-box img {
    /* width: 100%;
    height: auto; */
  }

  .discribe-content {
    width: 100%;
    min-height: 400px;
  }

  .discribe-text-box {
    color: #333333;
    font-size: 14px;
    min-height: 200px;
  }

  .programme-name {
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-size: 30px;
    font-weight: bold;
  }

  @media screen and (max-width: 768px) {
    .programme-name {
      font-size: 24px;
    }
  }

  .programme-name span {
    position: relative;
    left: 15px;
  }

  .pagination-box {
    min-height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .product-introduce {
    height: 30px;
    overflow: hidden;
    margin-bottom: 20px;
    font-size: 12px;
  }

  .case-item-box {
    width: calc(100 - 1px);
    height: 100px;
    cursor: pointer;
    display: flex;
  }
  .case-item-box:hover{
    background-color: #108bed;;
    color: #fff;
  }

  .case-info-div {
    margin: 20px 20px;
    flex: 1;
    height: auto;
  }

  .introduce-span {
    width: 320px;
    height: 40px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
  }

  @media screen and (max-width: 768px) {
    .introduce-span {
      width: 517px;
    }
  }

  @media screen and (max-width: 425px) {
    .introduce-span {
      width: 256px;
    }
  }

  @media screen and (max-width: 1024px) {
    .introduce-span {
      width: 218px;
    }
  }

  @media screen and (max-width: 1440px) {
    .introduce-span {
      width: 200px;
    }
  }

  .case-title {
    height: 40px;
    font-size: 22px;
  }
  @media screen and (max-width: 1440px) {
    .case-title {
      font-size: 16px;

    }
  }
</style>