/*
 * @Author: 李劲宏
 * @Date: 2022-10-31 15:40:44
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-10-31 15:40:54
 * @FilePath: /官网项目/src/utils/rem.js
 */

// AES 加密解密算法
import CryptoJS from 'crypto-js';
//加密
export const encrypt = (word, keyStr) => {
    keyStr = keyStr ? keyStr : 'CD_Right88889999'; //判断是否存在ksy，不存在就用定义好的key
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    var srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}
//解密
export const decrypt = (word, keyStr) => {
    keyStr = keyStr ? keyStr : 'CD_Right88889999';
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    var decrypt = CryptoJS.AES.decrypt(word, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
// 请求数据解密
// const key = CryptoJS.enc.Utf8.parse("CD_Right88889999")
// export const Decrypt = (word) => {
//     let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
//     console.log("解密前hex：" + encryptedHexStr)
//     let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
//     console.log("解密前：" + srcs)
//     let decrypt = CryptoJS.AES.decrypt(srcs, key, {
//         mode: CryptoJS.mode.CBC,    
//         padding: CryptoJS.pad.Pkcs7
//     });
//     let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
//     console.log("解密后:" + decryptedStr)
//     return decryptedStr.toString();
// }