/*
 * @Author: 李劲宏
 * @Date: 2022-04-21 23:12:02
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 09:23:35
 * @FilePath: /官网项目/src/store/index.js
 */

import {
    defineStore
} from 'pinia'

export const routerActive = defineStore('actives', {
    state: () => {
        return {
            active: 1
        }
    }
})

export const programmeActive = defineStore('tabActive', {
    state: () => {
        return {
            tabActive: ''
        }
    }
})