<!--
 * @Author: 李劲宏
 * @Date: 2022-10-31 11:21:12
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 13:15:32
 * @FilePath: /官网项目/src/components/header.vue
-->
<template>
  <div class="header-content">
    <div class="hover-box" v-show="hoverShow" @mouseover="hoverMouseover()" @mouseout="hoverMouseout()">
      <div class="hover-box-left">
        <span style="margin-right:20px">{{hoverName}}</span>
      </div>
      <div class="hover-box-right">
        <el-row :gutter="20">
          <el-col :span="2">
            <div></div>
          </el-col>
          <el-col :span="6" v-for="nav in hoverItemData" :key="nav.name">
            <p class="hover-box-right-title">{{nav.name}}</p>
            <p class="hover-box-right-item" @click="toDetailPath(child)" v-for="child in nav.child" :key="child.id">
              {{child.name}}</p>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-row>
      <el-col :span="6">
        <div class="logo">
          <img src="../assets/img/logo.png" alt="成都瑞特" class="logo-img" @click="toPath('/index')">
        </div>
      </el-col>
      <el-col :span="12">
        <div class="tab-content">
          <div class="tab-item" :class="{activebox: routerActives.active == tab.active}"
            @click="current(tab.active,tab.path)" @mouseover=" mouseover(tab)" @mouseout="mouseout(tab)"
            v-for="tab in tabs" :key="tab.active">
            <span>{{tab.name}}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="iocon-box">
          <el-popover placement="bottom" title="微信" :width="200" trigger="hover">
            <div>
              <el-row>
                <el-col :span="24" v-for=" w in wechatList" :key="w.id">
                  <div class="wechat-box">
                    <img :src="w.img" alt="微信"><br>
                    <span>
                      {{w.showName}}
                    </span>
                  </div>

                </el-col>
              </el-row>
            </div>
            <template #reference>
              <img src="../assets/img/home/wechat.png" alt="微信">
            </template>
          </el-popover>
          <el-popover placement="bottom" title="联系电话" :width="200" trigger="hover">
            <div class="phone-box" v-for=" p in phoneList" :key="p.id">
              <div class="phone-box-left">
                <span>{{p.showName}}</span>
              </div>
              <div class="phone-box-right">
                <span>{{p.phone}}</span>
              </div>
            </div>
            <template #reference>
              <img src="../assets/img/home/phone.png" alt="电话">
            </template>
          </el-popover>
        </div>
      </el-col>
    </el-row>
  </div>
  <div class="min-header-content">
    <el-row>
      <el-col :span="12">
        <div class="min-header-item">
          <img src="../assets/img/logo.png" alt="成都瑞特" class="min-logo-img">
        </div>
      </el-col>
      <el-col :span="12">
        <div>
          <div class="min-header-item nav-icon">
            <img src="../assets/img/home/nav-iocn.png" @click="munmShow" alt="菜单栏"
              style="width:30px;height:20px;cursor: pointer;">
          </div>
        </div>
      </el-col>
    </el-row>
    <div v-show="navShow" class="collapse">
      <el-collapse-transition>
        <div v-show="navShow">
          <div class="mnum-item" v-for=" m in tabs" :key="m.name" @click="toPath(m.path)">{{m.name}}</div>
        </div>

      </el-collapse-transition>
    </div>
  </div>
</template>
<script>
  import { encrypt } from '../utils/AES.js'
  import { reactive, toRefs, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import * as http from '../axios/home';
  import { routerActive } from '../store/index'
  export default {
    setup() {
      const router = useRouter();
      const data = reactive({
        navShow: false,
        wechatList: [],
        phoneList: [],
        hoverShow: false,
        hoverData: '',
        hoverItemData: '',
        hoverName: '',
        tabs: [{
          active: 1,
          name: '首页',
          path: '/index',
        }, {
          active: 2,
          name: '关于我们',
          path: '/about'
        },
        {
          active: 3,
          name: '解决方案',
          path: '/programme'
        }, {
          active: 4,
          name: '产品中心',
          path: '/product'
        }, {
          active: 5,
          name: '新闻中心',
          path: '/new'
        }, {
          active: 6,
          name: '人才招聘',
          path: '/recruit'
        }, {
          active: 7,
          name: '联系我们',
          path: '/contact'
        }]
      });
      const routerActives = routerActive();
      onMounted(async () => {
        let res = await http.homeAllData();
        if (res.code === 200) {
          data.hoverData = res.data;
        } else {
          console.log(res.msg)
        }
      });
      onMounted(() => {
        var sesionData = sessionStorage.getItem("active");
        if (sesionData) {
          routerActives.active = sesionData
        }
      })
      const current = (id, path) => {
        routerActives.active = id;
        sessionStorage.setItem("active", id);
        router.push(path)
      };
      const munmShow = () => {
        data.navShow = !data.navShow
      };
      const toPath = (path) => {
        data.navShow = false;
        router.push(path)
      };
      /**
       * @describe 获取微信信息
       */
      onMounted(async () => {
        let wechat = await http.wechatInfo({ type: 2 });
        let phone = await http.phoneInfo();
        data.wechatList = wechat.data;
        data.phoneList = phone.data;
      })
      // 获取导航栏解决方案数据
      const getNavSolution = async () => {
        data.hoverItemData = [];
        const res = await http.navSlutionData();
        data.hoverItemData = res.data;
      };
      // 获取导航栏产品中心数据
      const getNavProduct = async () => {
        data.hoverItemData = [];
        const res = await http.navProductData();
        data.hoverItemData = res.data;
      };
      // 导航栏鼠标获取事件
      const mouseover = (t) => {
        data.hoverName = t.name;
        if (t.name === '解决方案') {
          data.hoverShow = true;
          getNavSolution();
        } else if (t.name === '产品中心') {
          data.hoverShow = true;
          getNavProduct()
        } else {
          data.hoverShow = false;
        }
      };
      // 导航栏鼠标移出事件
      const mouseout = () => {
        // data.hoverShow = false;

      };
      // 导航栏悬浮框 鼠标事件
      const hoverMouseout = () => {
        data.hoverShow = false;
      };
      // 导航栏悬浮框 鼠标事件
      const hoverMouseover = () => {
        data.hoverShow = true;
      };
      // 路由跳转
      const toDetailPath = (child) => {
        if (child.type === 1) {
          routerActives.active = 3;
          sessionStorage.setItem("active", 3);
          router.push({
            path: '/programmedetail',
            query: {
              id: encrypt(child.id),
              // src: encrypt(child.detailImg)
            }
          })
        }
        if (child.type === 2) {
          routerActives.active = 4;
          sessionStorage.setItem("active", 4);
          router.push({
            path: '/productdetail',
            query: { id: encrypt(child.id) }
          })
        }
      }
      return {
        ...toRefs(data),
        current,
        munmShow,
        toPath,
        mouseover,
        mouseout,
        routerActives,
        hoverMouseover,
        hoverMouseout,
        toDetailPath
      }
    }
  }
</script>

<style scoped>
  .min-header-content {
    width: 100%;
    height: 60px;
    line-height: 60px;
    position: relative;
    display: none;
  }

  .min-header-item {
    width: 98%;
    height: 80px;
    line-height: 100px;
  }

  .nav-icon {
    text-align: right;
  }

  .header-content {
    width: 100%;
    margin-bottom: 80px;
    position: relative;
  }

  .hover-box {
    width:100%;
    height: 376px;
    background: rgba(255, 255, 255, 1);
    position: absolute;
    border-top: 1px solid #108bed;
    z-index: 20000;
    top: 80px;
    display: flex;
    box-shadow: 0px 10px 25px -15px rgba(0, 0, 0, .5);
    overflow: hidden;
  }

  .hover-box-left {
    width: 512px;
    height: 100%;
    /* border-right: 1px solid #dde2e6; */
    font-size: 30px;
    line-height: 100px;
    text-align: right;
  }

  .hover-box-right {
    flex: 1;
    height: 100%;
  }

  .hover-box-right-title {
    color: #108bed;
    font-size: 22px;
  }

  .hover-box-right-item {
    cursor: pointer;
  }

  .hover-box-right-item:hover {
    color: #0070c0;
  }

  @media screen and (max-width: 768px) {
    .header-content {
      display: none;
    }

    .min-header-content {
      display: block;
    }
  }

  .tab-content {
    width: 100%;
    display: flex;
  }

  .logo {
    width: 100%;
    height: 80px;
    /* text-align: right; */
    line-height: 110px;
    text-align: center;
    /* background-color: #108bed; */
  }

  /* @media screen and (max-width: 1400px) {
    .logo {
      line-height: 110px;
    }
  } */

  .logo-img {
    width: 166px;
    height: 51px;
    margin-right: 10%;
    cursor: pointer;
  }

  /* @media screen and (max-width: 1400px) {
    .logo-img {
      width: 120px;
      height: 30px;
      margin-right: 10%;
    }
  } */
  .min-logo-img {
    width: 166px;
    height: 51px;
    margin-right: 10%;
    cursor: pointer;
    position: relative;
    top: 5%;
  }

  .tab-item {
    width: 100%;
    flex: 1;
    height: 80px;
    text-align: center;
    line-height: 80px;
    cursor: pointer;
    font-size: 18px;
  }

  @media screen and (max-width: 1400px) {
    .tab-item {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1199px) {
    .tab-item {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1000px) {
    .tab-item {
      font-size: 12px;
    }
  }

  .tab-item :hover {
    color: #108bed;
  }

  .activebox {
    color: #0070c0;
  }

  .mnum-item {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #fff;
    background-color: #0070c0;
    line-height: 50px;
    color: #fff;
    text-align: center;
    font-weight: 600;
  }

  .collapse {
    width: 100%;
    position: absolute;
    top: 80px;
    z-index: 10000;
  }

  .iocon-box {
    width: 100%;
    line-height: 100px;
    text-align: center;
  }

  .iocon-box img {
    width: 32px;
    height: 32px;
    margin: 0 20px 0 0;
  }

  @media screen and (max-width: 1024px) {
    .iocon-box img {
      width: 30px;
      height: 30px;
    }
  }

  .wechat-box {
    /* width: 100px;
    height: 100px; */
    text-align: center;
    margin-bottom: 10px;
  }

  .wechat-box img {
    width: 40%;
    height: 40%;
  }

  .phone-box {
    width: 100%;
    display: flex;
    height: 30px;
  }

  .phone-box-left {
    width: 80px;
  }

  .phone-box-right {
    flex: 1;
  }
</style>