<!--
 * @Author: 李劲宏
 * @Date: 2022-11-07 22:11:34
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-07 22:11:40
 * @FilePath: /官网项目/src/view/404.vue
-->
<template>
  <div>
    404 页面不存在
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>