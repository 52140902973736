/*
 * @Author: 李劲宏
 * @Date: 2022-10-31 10:39:53
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-09 14:24:21
 * @FilePath: /官网项目/src/router/index.js
 */
import {
    createRouter,
    createWebHistory
} from 'vue-router'
import routes from './routes'

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    next()

})

export default router