<!--
 * @Author: 李劲宏
 * @Date: 2022-11-01 14:32:05
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 13:53:21
 * @FilePath: /官网项目/src/view/newDetail.vue
-->
<template>
  <div class="new-bg">
    <img :src="bannerImg" alt="新闻中心" style="width: 100%;">
  </div>
  <div class="new-content">
    <el-row>
      <el-col 
      :xs="{span:22,offset:1}" 
      :sm="{span:20,offset:2}"
      :xl="{span:14,offset:5}"
      :lg="{span:14,offset:5}"
      >
        <div class="page-lable">
          <span class="page-lable-router" @click="toPath('/index',1)">首页 </span> >
          <span class="page-lable-router" @click="toPath('/new',5)">新闻中心 </span> >
          <span> 新闻详情</span>
          <!-- 首页 > 新闻中心 > 新闻详情 -->
        </div>
        <div class="new-main">
          <div v-html="content.content" class="new-fu">
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getBanner } from '../axios/home.js';
import * as AES from '../utils/AES.js';
import { onMounted, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import * as http from '../axios/new';
import { routerActive } from '../store/index';
export default {
  metaInfo: {
    title: '新闻中心_智慧燃气-数字化工厂-智能仓储-成都瑞特',
    meta: [   // set meta
      {
        name: 'keyWords',
        content: '什么是智慧燃气,智慧燃气综合管理平台'
      },
      {
        name: 'description',
        content: '成都瑞特利用数字化,自动化,网络化和智能化为特征的现代新型技术,致力于智慧燃气,智能仓储,非标自动化, 智慧燃气平台等业务, 一直为全球500强企业Emerson Fisher等公司提供专业,优质的服务.公司搭建有智慧燃气综合管理平台，为不懂者进行解惑。'
      }
    ]
  },
  setup () {
    const $router = useRouter();
    const newDetailData = reactive({
      content:'',
      bannerImg:''
    });
    //获取banner图
    onMounted(async () => {
        let res = await getBanner({ id: 5 });
        if (res.code === 200) {
          newDetailData.bannerImg = res.data[0].img;
        } else {
          console.log(res.msg);
        }
      })
    onMounted(async () => {
      let _id = AES.decrypt($router.currentRoute.value.query.id);
      let res = await http.newDetailsData({ id: _id });
      const { code, data, msg } = res;
      if (code === 200) {
        newDetailData.content = data
      }else{
        console.log(msg);
      }
    });
    const routerActives = routerActive();
    const toPath = (path,active) => {
      routerActives.active = active;
      sessionStorage.setItem("active", active);
      $router.push(path);
    }
    return {
      ...toRefs(newDetailData),
      toPath
    }
  }
}
</script>

<style scoped>
.new-bg {
  width: 100%;
  height: 380px;
  margin-top: 20px;
}
@media screen and (max-width: 1440px) {
  .new-bg {
    height: 300px;
  }
}
@media screen and (max-width: 1080px) {
  .new-bg {
    height: 250px;
  }
}
@media screen and (max-width: 768px) {
  .new-bg {
    height: 200px;
  }
}
@media screen and (max-width: 425px) {
  .new-bg {
    height: 150px;
  }
}
.new-content {
  margin: 20px 0;
  /* min-height: 1200px; */
  height: auto;
}

.new-main {
  width: 100%;
  min-height: 1200px;
  margin-bottom: 30px;
  overflow: hidden;
}
.new-main img{
  max-width: 100%;
}
.new-fu{

}
.new-fu img {
  max-width: 100%;
}
</style>