/*
 * @Author: 李劲宏
 * @Date: 2022-11-07 13:49:02
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 10:23:50
 * @FilePath: /官网项目/src/axios/home.js
 */
import httpRequest from './index.js'
// 查询微信信息
export const wechatInfo = param => {
	return httpRequest({
		url: '/companyserver/api/index/wechat/getWebListByCondition',
		method: 'post',
		data: param,
	})
}
// 查询电话信息
export const phoneInfo = () => {
	return httpRequest({
		url: '/companyserver/api/index/phone/getWebListByCondition',
		method: 'post',
	})
}
// 查询首页数据
export const homeAllData = () => {
	return httpRequest({
		url: '/companyserver/api/index/getIndexData',
		method: 'post',
	})
}
// 查询导航栏 解决方案列表
export const navSlutionData = () => {
	return httpRequest({
		url: '/companyserver/api/solution/solutionCategoryAndScheme',
		method: 'post',
	})
}
// 查询导航栏 产品中心列表
export const navProductData = () => {
	return httpRequest({
		url: '/companyserver/api/product/categoryAndProduct',
		method: 'post',
	})
}
// 获取各个板块banner图
export const getBanner = (param) => {
	return httpRequest({
		url: '/companyserver/api/menu/menuBannerList',
		method: 'post',
		data: param
	})
}