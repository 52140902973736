<!--
 * @Author: 李劲宏
 * @Date: 2022-11-01 14:15:13
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 13:55:37
 * @FilePath: /官网项目/src/view/recruit.vue
-->
<template>
  <div class="recruit-bg">
    <img :src="bannerImg" alt="人才招聘" style="width: 100%;">
  </div>
  <div class="contect">
    <el-row :gutter="20">
      <el-col :xs="{span:22,offset:1}" :sm="{span:20,offset:2}" :xl="{span:16,offset:4}" :lg="{span:12,offset:6}">
        <div class="page-lable">
          <span class="page-lable-router" @click="toPath()">首页 </span> >
          <span> 人才招聘</span>
        </div>
        <div class="recruit-main">
          <div class="left"></div>
          <div class="center">
            <div class="center-top">
              <span>职位招聘</span>
              <el-select v-model="positionType" placeholder="请选择职能类型" class="select-box">
                <el-option v-for="item in positionList" :key="item.code" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
              <el-button type="primary" @click="getRecruit">查询</el-button>
              <el-button type="info" @click="reset">清空</el-button>
            </div>
            <div class="center-item" v-for="item in tableList" :key="item.id">
              <div class="center-item-describe">
                <div class="recruit-info">
                  <span class="job">{{item.webPersonnelPositionName}}</span>
                  <span class="new-job">NEW</span>
                  <br>
                  <span class="add">{{item.location}}&#12288;{{item.experience}}</span><br>
                  <span class="time">发布时间: {{item.createTime.slice(0,11)}}</span>
                </div>
              </div>
              <div class="center-item-button">
                <div class="button-div" @click="toDetailPath(item.id)">查看详情</div>
              </div>
            </div>
          </div>
          <div class="right">
          </div>
        </div>
        <div class="pagination-box">
          <el-pagination small background layout="prev, pager, next" @current-change="handleCurrentChange"
            :page-size="pageSize" :current-page="pageNum" :total="totalCount" />
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
  import { getBanner } from '../axios/home.js';
  import { encrypt } from '../utils/AES.js';
  import * as http from '../axios/recruit';
  import { reactive, toRefs, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { routerActive } from '../store/index';
  export default {
    metaInfo: {
      title: '人才招聘_智慧燃气-数字化工厂-智能仓储-成都瑞特',
      meta: [   // set meta
        {
          name: 'keyWords',
          content: '智能工厂mes管理,智能工厂调度'
        },
        {
          name: 'description',
          content: '成都瑞特利用数字化,自动化,网络化和智能化为特征的现代新型技术,致力于智慧燃气,智能仓储,非标自动化, 智慧燃气平台等业务, 公司进行智能工厂mes管理和智能工厂调度，让用户获得优质的服务。'
        }
      ]
    },
    setup() {
      const $router = useRouter();
      const recruitData = reactive({
        value: '',
        options: [],
        pageNum: 1,
        pageSize: 8,
        tableList: [],
        totalCount: 0,
        positionType: '', //职位类型
        positionList: [],
        bannerImg:''
      });
      //获取banne图
      onMounted(async () => {
        let res = await getBanner({ id: 6 });
        if (res.code === 200) {
          recruitData.bannerImg = res.data[0].img;
        } else {
          console.log(res.msg);
        }
      });
      //获取下拉列表
      onMounted(async () => {
        let p = await http.positionSelects();
        recruitData.positionList = p.data;
      });
      /**
       * @author: ljh
       * @api: 
       * @description: 获取招聘职位列表
       * @return {*}
       */
      const getRecruit = async () => {
        let params = {
          pageSize: recruitData.pageSize,
          pageNum: recruitData.pageNum,
          webPersonnelPositionId: recruitData.positionType || undefined,
        };
        let res = await http.recruitList(params);
        const { data, code, msg, count } = res;
        if (code === 200) {
          recruitData.totalCount = count;
          recruitData.tableList = data;
        } else {
          console.log(msg)
        }
      };
      /**
       * @description 重置查询
       */
      const reset = () => {
        recruitData.positionType = '';
        recruitData.experience = '';
        getRecruit()
      };
      /**
       * @description 分页查询
       */
      const handleCurrentChange = (pageNum) => {
        recruitData.pageNum = pageNum;
        getRecruit();
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      };
      /**
       * @description 初始化请求
       */
      onMounted(() => {
        getRecruit();
      });
      // 跳转详情页面
      const toDetailPath = (id) => {
        $router.push({
          path: '/recruitdetail',
          query: { id: encrypt(id) }
        })
      };
      const routerActives = routerActive();
      const toPath = () => {
        routerActives.active = 1;
        sessionStorage.setItem("active", 1);
        $router.push('/index');
      }
      return {
        ...toRefs(recruitData),
        getRecruit,
        handleCurrentChange,
        toDetailPath,
        reset,
        toPath
      }
    }
  }
</script>

<style scoped>
  .recruit-bg {
    width: 100%;
    height: 380px;
    margin-top: 20px;
  }

  @media screen and (max-width: 1440px) {
    .recruit-bg {
      height: 314px;
    }
  }

  @media screen and (max-width: 1080px) {
    .recruit-bg {
      height: 230px;
    }
  }

  @media screen and (max-width: 768px) {
    .recruit-bg {
      height: 167px;
    }
  }

  @media screen and (max-width: 425px) {
    .recruit-bg {
      height: 105px;
    }
  }

  .contect {
    margin-top: 80px;
    overflow: hidden;
  }

  .recruit-main {
    width: 100%;
    min-height: 1050px;
    border: 1px solid #dde2e6;
    display: flex;
  }

  .recruit-main .left {
    width: 10px;
  }

  .recruit-main .center {
    width: 100%;
    flex: 1;
  }

  .recruit-main .right {
    width: 10px;
  }

  .center-top {
    width: 100%;
    min-height: 100px;
    font-size: 18px;
    line-height: 100px;
  }

  .center-item {
    width: 100%;
    height: 120px;
    border-bottom: 1px solid #dde2e6;
    display: flex;
  }

  .center-item-describe {
    flex: 1;
    height: 120px;
  }

  .center-item-button {
    flex: 1;
    height: 120px;
    line-height: 120px;
    text-align: right;
    padding-right: 20px;
  }

  .button-div {
    display: inline-block;
    width: 104px;
    height: 40px;
    background: #108bed;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .button-div {
      width: 90px;
      height: 33px;
      line-height: 33px;
    }
  }

  .recruit-info {
    margin-top: 20px;
  }

  .recruit-info .job {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
  }

  .recruit-info .new-job {
    display: inline-block;
    width: 30px;
    height: 14px;
    text-align: center;
    line-height: 14px;
    font-size: 12px;
    color: #f95858;
    border: 1px solid #f95858;
    position: relative;
    left: 10px;
    top: -2px;
  }

  .recruit-info .add {
    font-size: 14px;
    color: #333333;
    margin: 10px 0;
    display: inline-block;
  }

  .recruit-info .time {
    font-size: 14px;
    color: #b0b2bf;
  }

  .recruit-main-right {
    width: 100%;
    height: 327px;
    border: 1px solid #dde2e6;
    margin-top: 80px;
  }

  .pagination-box {
    min-height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px;
  }

  .select-box {
    margin: 0 20px;
  }

  @media screen and (max-width: 425px) {
    .select-box {
      display: block;
      margin: 10px 0;
      position: relative;
      top: -20px;
    }
  }
</style>