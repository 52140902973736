/*
 * @Author: 李劲宏
 * @Date: 2022-10-31 10:40:49
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-08 16:20:08
 * @FilePath: /官网项目/src/router/routes.js
 */
import Home from '@/view/index';
import About from '@/view/about';
import Contact from '@/view/contact';
import Recruit from '@/view/recruit';
import RecruitDetail from '../view/recruitDetail';
import ProductDetail from '../view/productDetail';
import Product from '@/view/product';
import New from '@/view/new';
import Newdetail from '../view/newDetail';
import Programme from '@/view/programme';
import ProgrammeDetail from '@/view/programmeDetail';
import CaseDetail from '@/view/caseDetail'
import Nopage from '../view/nopage'
const routes = [{
        path: '/',
        redirect: '/index'
    },
    {
        name: 'home',
        path: '/index', //首页
        component: Home
    },
    {
        name: 'about',
        path: '/about', // 关于我们
        component: About
    },
    {
        name: 'contact',
        path: '/contact',
        component: Contact
    },
    {
        name: 'recruit',
        path: '/recruit', // 人才招聘
        component: Recruit 
    },
    {
        name: 'recruitDetail',
        path: '/recruitdetail',
        component:RecruitDetail
    },
    {
        name: 'product',
        path: '/product',
        component: Product
    },
    {
        name: 'productDetail',
        path: '/productdetail',
        component: ProductDetail
    },
    {
        name: 'new', 
        path: '/new', // 新闻页
        component: New
    },
    {
        name: 'newDetail', 
        path: '/newdetail', // 新闻详情页
        component: Newdetail
    },
    {
        name: 'programme',
        path: '/programme',  // 解决方案
        component: Programme
    }, 
    {
        name:'programmeDetail',
        path:'/programmedetail',  // 方案详情
        component:ProgrammeDetail
    },
    {
        name:'caseDetail',
        path:'/casedetail',  // 方案详情
        component:CaseDetail
    },
    {
        name: 'nopage',
        path: '/nopage',
        component: Nopage
    }, 
];
export default routes