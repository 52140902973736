/*
 * @Author: 李劲宏
 * @Date: 2022-11-07 08:47:58
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-07 08:51:19
 * @FilePath: /官网项目/src/axios/about.js
 */
import httpRequest from './index.js'

// 查询公司信息
export const aboutInfo = () => {
    return httpRequest({
        url: '/companyserver/api/aboutUs/profile/getWebListAboutUsProfile',
        method: 'post',
    })
}
// 查询公司荣誉资质
export const translate = (param) => {
    return httpRequest({
        url: '/companyserver/api/aboutUs/profile/getWebPageQualificationsByCondition',
        method: 'post',
        data: param
    })
}