<!--
 * @Author: 李劲宏
 * @Date: 2022-11-01 14:32:05
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 13:46:51
 * @FilePath: /官网项目/src/view/new.vue
-->
<template>
  <div class="new-bg">
    <img :src="bannerImg" alt="新闻中心" style="width: 100%;">
  </div>
  <div class="new-content">
    <el-row>
      <el-col :span="18" :offset="3">
        <div class="page-lable">
          <span class="page-lable-router" @click="toPath()">首页 </span> >
          <span> 新闻中心</span>
        </div>
        <div class="new-main">
          <div class="new-tabs">
            <el-tabs v-model="activeName" @tab-click="tabClick">
              <el-tab-pane :label="tab.name" :name="tab.id" v-for="tab in typeList" :key="tab.id">
                <div class="new-main-item" v-for="item in newList" :key="item" @click="toDetailPath(item.id)">
                  <el-row :gutter="15">
                    <el-col :span="5" :xs="24">
                      <div class="item-img-box">
                        <img :src="item.img" class="item-img" alt="新闻">
                      </div>
                    </el-col>
                    <el-col :span="16" :xs="24">
                      <div class="item-info">
                        <h3>{{item.title}}</h3>
                        <p>[ {{item.category}} ]</p>
                        <span>{{item.profile}}</span>
                      </div>
                    </el-col>
                    <el-col :span="3" :xs="12">
                      <div class="date-box">
                        <span class="date-span">{{item.publishTime.slice(5,11)}}</span><br>
                        <span>{{item.publishTime.slice(0,4)}}</span>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </el-col>
    </el-row>

  </div>
  <div class="pagination-box">
    <el-pagination small background layout="prev, pager, next" @current-change="handleCurrentChange"
      :current-page="pageNum" :page-size="pageSize" :total="totalCount" />
  </div>
</template>
<script>
  import { getBanner } from '../axios/home.js';
  import * as AES from '../utils/AES.js';
  import { onMounted, reactive, toRefs } from 'vue';
  import { useRouter } from 'vue-router';
  import * as http from '../axios/new';
  import { routerActive } from '../store/index';
  export default {
    metaInfo: {
      title: '新闻中心_智慧燃气-数字化工厂-智能仓储-成都瑞特',
      meta: [   // set meta
        {
          name: 'keyWords',
          content: '什么是智慧燃气,智慧燃气综合管理平台'
        },
        {
          name: 'description',
          content: '成都瑞特利用数字化,自动化,网络化和智能化为特征的现代新型技术,致力于智慧燃气,智能仓储,非标自动化, 智慧燃气平台等业务, 一直为全球500强企业Emerson Fisher等公司提供专业,优质的服务.公司搭建有智慧燃气综合管理平台，为不懂者进行解惑。'
        }
      ]
    },
    setup() {
      const $router = useRouter();
      const newData = reactive({
        activeName: '',
        typeList: [],
        newList: [],
        totalCount: 0,
        pageNum: 1,
        pageSize: 6,
        bannerImg:'',
      });
      //获取banner图
      onMounted(async () => {
        let res = await getBanner({ id: 5 });
        if (res.code === 200) {
          newData.bannerImg = res.data[0].img;
        } else {
          console.log(res.msg);
        }
      })
      /**
       * @author: ljh
       * @api: 
       * @description: 获取新闻类型列表
       */
      const routeType = $router.currentRoute.value.query.type;
      onMounted(async () => {
        let res = await http.newTypeList();
        const { data, msg, code } = res;
        if (code === 200) {
          newData.typeList = data;
          if (routeType) {
            newData.activeName = Number(routeType);
            getPageList(routeType)
          } else {
            newData.activeName = data[0].id;
            getPageList(data[0].id)
          }
        } else {
          console.log(msg)
        }
      });
      /**
       * @author: ljh
       * @api: 
       * @description: 获取新闻列表
       * @param {int} id 
       */
      const getPageList = async (id) => {
        let params = {
          pageNum: newData.pageNum,
          pageSize: newData.pageSize,
          newsCategoryId: id
        };
        let res = await http.newPageList(params);
        const { data, code, msg, count } = res;
        if (code === 200) {
          newData.totalCount = count;
          newData.newList = data;
        } else {
          console.log(msg)
        }
      };
      const tabClick = async (pane) => {
        newData.pageNum = 1;
        getPageList(pane.props.name)
      };
      const handleCurrentChange = (pageNum) => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        newData.pageNum = pageNum;
        getPageList();
      }
      // 跳转至详情页
      const toDetailPath = (id) => {
        $router.push(
          {
            path: '/newdetail',
            query: { id: AES.encrypt(id) }
          }
        );
      };
      const routerActives = routerActive();
      const toPath = () => {
        routerActives.active = 1;
        sessionStorage.setItem("active", 1);
        $router.push('/index');
      }
      return {
        ...toRefs(newData),
        tabClick,
        handleCurrentChange,
        toDetailPath,
        toPath
      }
    }
  }
</script>

<style scoped>
  .new-bg {
    width: 100%;
    height: 380px;
    margin-top: 20px;
  }

  @media screen and (max-width: 1440px) {
    .new-bg {
      height: 300px;
    }
  }

  @media screen and (max-width: 1080px) {
    .new-bg {
      height: 250px;
    }
  }

  @media screen and (max-width: 768px) {
    .new-bg {
      height: 200px;
    }
  }

  @media screen and (max-width: 425px) {
    .new-bg {
      height: 150px;
    }
  }

  .new-content {
    margin: 20px 0 50px 0;
    min-height: 1500px;
  }

  .new-tabs {
    width: 100%;
    height: 40px;
    margin: 15px 0;
  }

  .new-main {
    width: 100%;
    height: 1200px;
    margin-bottom: 30px;
  }

  .new-main-item {
    width: calc(100%-10px);
    min-height: 180px;
    border: 1px solid #3333;
    margin: 20px 0;
    cursor: pointer;
  }

  .new-main-item :hover {
    background: rgba(16, 139, 237, 1);
    color: #fff;
  }

  .item-img-box {
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item-img {
    width: 85%;
    height: 80%;
  }

  .item-info {
    width: 100%;
    max-height: 180px;
    font-size: 14px;
    overflow: hidden;
  }
  /* .item-info p{
    color: #B0B2BF;
  }
  .item-info span{
    color: #666874;
    line-height: 25px;
  }
  .item-info h3{
    color: #000;
  } */

  .date-box {
    color: #b0b2bf;
    padding-top: 30px;
    text-align: center;
  }

  .date-span {
    font-size: 24px;
  }

  .pagination-box {
    min-height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
</style>