/*
 * @Author: 李劲宏
 * @Date: 2022-03-11 23:35:36
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 09:22:24
 * @FilePath: /官网项目/src/main.js
 */
import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import MetaInfo from 'vue-meta-info'
import router from './router/index'
import './assets/css/base.css'

const app = createApp(App);
app.use(ElementPlus)
app.use(createPinia())
app.use(MetaInfo)
app.use(router)
app.mount('#app')