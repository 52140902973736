<!--
 * @Author: 李劲宏
 * @Date: 2022-11-01 14:32:31
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 14:05:26
 * @FilePath: /官网项目/src/view/product.vue
-->
<template>
  <div class="product-bg">
    <img :src="bannerImg" alt="产品中心" style="width: 100%;">
  </div>
  <div class="product">
    <el-row>
      <el-col :xs="{span:22,offset:1}" :sm="{span:18,offset:3}" :xl="{span:16,offset:4}" :lg="{span:16,offset:4}">
        <div class="page-lable">
          <span class="page-lable-router" @click="toPath()">首页 </span> >
          <span> 产品中心</span>
        </div>
        <div v-for=" product in dataList" :key="product.id">
          <div class="product-type-name">
            <div class="diamond"></div>
            <span>{{product.name}}</span>
          </div>
          <div class="produc-item">
            <el-row :gutter="20">
              <el-col :span="8" :xs="24" v-for="item in product.webProducts " :key="item.id">
                <div class="product-item-content">
                  <div class="product-item-img">
                    <img :src="item.coverImg" :alt="item.name" style="width:100%;height: 100%;">
                  </div>
                  <div class="product-item-name" @click="toDetailPath(item.id)">
                    <span class="product-item-span">{{item.name}}</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { getBanner } from '../axios/home.js';
  import { encrypt } from '../utils/AES.js';
  import { reactive, toRefs, onMounted } from 'vue';
  import { productList } from '../axios/product'
  import { useRouter } from 'vue-router';
  import { routerActive } from '../store/index';
  export default {
    metaInfo: {
      title: '产品中心_智慧燃气-数字化工厂-智能仓储-成都瑞特',
      meta: [   // set meta
        {
          name: 'keyWords',
          content: '智能仓储解决方案企业,智能工厂信息化建设,智慧燃气建设,智慧燃气服务者'
        },
        {
          name: 'description',
          content: '成都瑞特利用数字化,自动化,网络化和智能化为特征的现代新型技术,致力于智慧燃气,智能仓储,非标自动化,数字化工厂等业务,为企业提供智能仓储解决方案企业同时也为智能工厂信息化建设提供了贡献。'
        }
      ]
    },
    setup() {
      const $router = useRouter();
      const productData = reactive(
        {
          dataList: [],
          bannerImg:''
        }
      );
      //获取banner图
      onMounted(async () => {
        let res = await getBanner({ id: 4 });
        if (res.code === 200) {
          productData.bannerImg = res.data[0].img;
        } else {
          console.log(res.msg);
        }
      })
      /**
       * @description 获取产品列表
       */
      onMounted(async () => {
        let res = await productList();
        const { data } = res;
        productData.dataList = data
      });
      // 跳转详情页
      const toDetailPath = (id) => {
        $router.push(
          {
            path: '/productdetail',
            query: { 
              id: encrypt(id)
            }
          }
        )
      };
      const routerActives = routerActive();
      const toPath = () => {
        routerActives.active = 1;
        sessionStorage.setItem("active", 1);
        $router.push('/index');
      }
      return {
        ...toRefs(productData),
        toDetailPath,
        toPath
      }
    }
  }
</script>

<style scoped>
  .product-bg {
    width: 100%;
    height: 380px;
    margin-top: 20px;
  }

  @media screen and (max-width: 1440px) {
    .product-bg {
      height: 314px;
    }
  }

  @media screen and (max-width: 1080px) {
    .product-bg {
      height: 230px;
    }
  }

  @media screen and (max-width: 768px) {
    .product-bg {
      height: 167px;
    }
  }

  @media screen and (max-width: 425px) {
    .product-bg {
      height: 105px;
    }
  }

  .product {
    margin-top: 20px;
    height: auto;
  }

  .product-type-name {
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-size: 20px;
    font-weight: bold;
  }

  .product-type-name span {
    position: relative;
    left: 15px;
    top: -5px;
  }
</style>