<!--
 * @Author: 李劲宏
 * @Date: 2022-11-01 14:33:11
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 13:30:06
 * @FilePath: /官网项目/src/view/programme.vue
-->
<template>
  <div class="programme-bg">
    <img :src="bannerImg" alt="解决方案" style="width: 100%;">
  </div>
  <div style="min-height:1200px">
    <el-row>
      <el-col :xs="{span:22,offset:1}" :sm="{span:18,offset:3}" :xl="{span:16,offset:4}" :lg="{span:16,offset:4}">
        <div class="page-lable">
          <span class="page-lable-router" @click="toPath()">首页 </span> >
          <span> 解决方案</span>
        </div>
        <div class="pro-tab">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="tab.name" :name="tab.id" v-for=" tab in typeList" :key=" tab.id">
              <div class="discribe-content">
                <el-row :gutter="30">
                  <el-col :span="12" :xs="24">
                    <h3>{{tab.name}}概述</h3>
                    <div class="discribe-text-box">
                      <span>{{tab.summary}}</span>
                    </div>
                  </el-col>
                  <el-col :span="12" :xs="24">
                    <div class="img-box">
                      <img :src="tab.img" :alt="tab.name">
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="programme-name">
          <div class="diamond">
          </div>
          <span>解决方案</span>
        </div>
        <div>
          <div class="produc-item">
            <el-row :gutter="20">
              <el-col :span="8" :xs="24" v-for="p in productList " :key="p.id">
                <div class="product-item-content">
                  <div class="product-item-img">
                    <img :src="p.coverImg" :alt="p.name" style="width:100%;height: 100%;">
                  </div>
                  <div class="product-item-name" @click="toDetailPath(p.id)">
                    <span class="product-item-span">{{p.name}}</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { getBanner } from '../axios/home.js';
  import { encrypt } from '../utils/AES.js';
  import { onMounted, reactive, toRefs } from 'vue';
  import { useRouter } from 'vue-router';
  import * as http from '../axios/programme';
  import { routerActive , programmeActive } from '../store/index';
  export default {
    metaInfo: {
      title: '解决方案_智慧燃气-数字化工厂-智能仓储-成都瑞特',
      meta: [   // set meta
        {
          name: 'keyWords',
          content: '智慧燃气市场规模,智慧燃气行业平台'
        },
        {
          name: 'description',
          content: '公司为了适应市场发展，提高自身技术竞争力，本着优势互补、合作共赢的原则，在智慧燃气行业平台拥有多位资深技术专家，如今在智慧燃气市场规模非常壮大， 以西南交大智能机电所为技术后盾，核心技术团队19人：高级职称8人，中级职称11人，获得博士学位者7人，硕士学位12人，另有兼职在读硕士生30多人，博士研究'
        }
      ]
    },
    setup() {
      const $router = useRouter();
      const proData = reactive({
        activeName: '',
        typeList: [],
        productList: [],
        bannerImg:''
      });
      //获取banner图
      onMounted(async () => {
        let res = await getBanner({ id: 3 });
        if (res.code === 200) {
          proData.bannerImg = res.data[0].img;
        } else {
          console.log(res.msg);
        }
      })
      /**
       * @describe 获取方案类型数据
       */
      const programmeActives = programmeActive ();
      const routeType = $router.currentRoute.value.query.type;
      onMounted(async () => {
        let res = await http.programmeType();
        const { data, code, msg } = res;
        if (code === 200) {
          proData.typeList = data;
          if (routeType) {
            proData.activeName = Number(routeType);
            getProgrammeForId(routeType)
          }else if(programmeActives.tabActive){
            proData.activeName = Number(programmeActives.tabActive);
            getProgrammeForId(proData.activeName);
          } else {
            proData.activeName = data[0].id;
            getProgrammeForId(data[0].id)
          }
        } else {
          console.log(msg)
        }
      })
      /**
       * @describe 根据方案类型id 获取对应产品方案
       */
      const getProgrammeForId = async (id) => {
        let params = { categoryId: id };
        let res = await http.programmeOfID(params);
        const { data, code, msg } = res;
        if (code === 200) {
          proData.productList = data;
        } else {
          console.log(msg)
        }
      };
      /**
       * @describe tab切换点击事件
       */
      const handleClick = () => {
        programmeActives.tabActive = proData.activeName;
        // console.log(programmeActives.tabActive);
        getProgrammeForId(proData.activeName)
      };
      //跳转详情
      const toDetailPath = (id) => {
        $router.push(
          {
            path: '/programmedetail',
            query: {
              id: encrypt(id),
            }
          }
        )
      };
      const routerActives = routerActive();
      const toPath = () => {
        routerActives.active = 1;
        sessionStorage.setItem("active", 1);
        $router.push('/index');
      }
      return {
        ...toRefs(proData),
        handleClick,
        toDetailPath,
        toPath
      }
    }
  }
</script>

<style scoped>
  .programme-bg {
    width: 100%;
    height: 380px;
    margin-top: 20px;
  }

  @media screen and (max-width: 1440px) {
    .programme-bg {
      height: 314px;
    }
  }

  @media screen and (max-width: 1080px) {
    .programme-bg {
      height: 230px;
    }
  }

  @media screen and (max-width: 768px) {
    .programme-bg {
      height: 167px;
    }
  }

  @media screen and (max-width: 425px) {
    .programme-bg {
      height: 105px;
    }
  }

  .pro-tab {
    width: 100%;
    min-height: 400px;
    margin-bottom: 40px;
  }

  .discribe-content {
    width: 100%;
    min-height: 400px;
  }

  .discribe-text-box {
    color: #333333;
    font-size: 16px;
    min-height: 200px;
    line-height: 30px;
  }

  .img-box {
    width: 100%;
    height: 400px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .img-box .img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 425px) {
    .img-box {
      height: 284px;
    }
  }

  .programme-name {
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-size: 30px;
    font-weight: bold;
    margin-top: 80px;
  }

  @media screen and (max-width: 768px) {
    .programme-name {
      font-size: 24px;
    }
  }

  .programme-name span {
    position: relative;
    left: 15px;
    /* top: -5px; */
  }
</style>