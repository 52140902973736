<!--
 * @Author: 李劲宏
 * @Date: 2022-10-31 10:43:09
 * @LastEditors: 曹柯
 * @LastEditTime: 2022-11-14 13:25:39
 * @FilePath: \officialwebsite\src\view\index.vue
-->
<template>
  <div v-if="carousel.length">
    <!-- 轮播图 -->
    <div class="banar">
      <el-carousel :interval="5000" :height="bannerHeight" v-if="carousel.length">
        <el-carousel-item v-for='item in carousel' :key="item.id" :initial-index="1">
          <img :src="item.img" alt="" @click="carouselTo(item.route,3)" style="cursor:pointer;">
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 解决方案 -->
    <div class="title-content">
      <p class="title-english">SOLUTIONS</p>
      <p class="title-chinese">解决方案</p>
    </div>
    <div class="partner-content">
      <div class="left"></div>
      <div class="center">
        <el-row :gutter="20">
          <el-col :xs='24' :span="12">
            <div class="solution-zz" @click="seeMore('/programme',3,4)">
              <img src="../assets/img/home/zhrq.png" alt="智慧燃气" class="solution-image">
              <p class="solution-describe">智慧燃气解决方案</p>
            </div>
          </el-col>
          <el-col :xs='24' :span="12">
            <div class="solution-zz" @click="seeMore('/programme',3,3)">
              <img src="../assets/img/home/znzz.png" alt="智能制造" class="solution-image">
              <p class="solution-describe">智能制造解决方案</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="right"></div>
    </div>
    <!-- 产品中心 -->
    <div class="product-content">
      <div class="title-content">
        <p class="title-english">PRODUCT CENTER</p>
        <p class="title-chinese">产品中心</p>
      </div>
      <div class="product-tabs">
        <el-tabs v-model="productActiveName" class="demo-tabs" @tab-click="productHandleClick">
          <el-tab-pane :label="tab.name" :name="tab.id" v-for="tab in product" :key="tab.id">
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="card-box">
        <div class="partner-content">
          <div class="left"></div>
          <div class="center">
            <el-row :gutter="20">
              <el-col :span="8" :xs="24" v-for="el in productCurData.slice(0,3)" :key="el.id">
                <div class="card-content" style="height: 342px;"
                  @click="toProductDetail('/productdetail',el.id,4)">
                  <div class="product-card-img-box">
                    <img :src="el.coverImg" :alt="el.name" class="card-content-img">
                  </div>
                  <div class="product-card-footer">
                    <span>{{el.name}}</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="right"></div>
        </div>
      </div>
      <div class="product-more">
        <div class="more-button" @click="seeMore('/product',4)">
          <span>查看更多</span>
          <i class="el-icon-right" style="color:red;font-size: 16px;"></i>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="title-content">
      <p class="title-english">ABOUT US</p>
      <p class="title-chinese">关于我们</p>
    </div>
    <div class="partner-content">
      <div class="left"></div>
      <div class="center">
        <el-row :gutter="40">
          <el-col :xs='24' :span="12">
            <div
              :class="[{'about-bg': activeName =='a'},{'wenhua-bg': activeName =='b'},{'zizhi-bg': activeName =='c'}]">
            </div>
          </el-col>
          <el-col :xs='24' :span="12">
            <div class="about-content">
              <div class="about-content-tabs">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane label="公司简介" name="a">
                    <span
                      class="about-content-tabs-span">成都瑞特数字科技有限责任公司自2005年成立以来，以“应用新技术，促进智能制造技术和智能制造系统的研究”为使命，与原煤炭部最高学府“中国矿业大学”和原铁道部最高学府“西南交通大学”紧密合作，利用数字化、自动化、网络化和智能化为特征的现代新型技...</span>
                  </el-tab-pane>
                  <el-tab-pane label="企业文化" name="b">
                    <span class="about-content-tabs-span">
                      公司始终以“工业生产更安全，人民生活更智能”为愿景；以“自信、自醒、自悟、自修”为精神；以“以人为本，共谋发展”为宗旨；秉承合作、共赢
                      、发展的核心价值观，以科技为先导，以创新求发展；以质量求生存，以诚信待客户。
                    </span>
                  </el-tab-pane>
                  <el-tab-pane label="荣誉资质" name="c">
                    <span class="about-content-tabs-span">
                      公司荣获“专精特新企业认定”、“高新技术企业证书”、“质量管理体系认证证书”、“两化融合管理体系评定”、“防爆电气设备安装能力认证证书”、“软件企业证书”、“软件产品证书”，以及多项专利和30余项软件著作权。
                    </span>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <div class="about-content-footer">
                <div class="about-content-btn-div" @click="seeMore('/about',2)">
                  <span>查看详情</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top:40px">
          <el-row>
            <el-col :span="6" :xs="12">
              <div class="about-item">
                <img src="../assets/img/home/company-icon.png" alt="成都瑞特" class="about-icon">
                <span class="about-describe-span">成都瑞特成立于</span><br>
                <span class="about-describe-span2">{{about.setUp}}</span>
                <span class="about-describe-span">年 </span>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div class="about-item">
                <img src="../assets/img/home/experience-icon.png" alt="成都瑞特" class="about-icon">
                <span class="about-describe-span">项目实施经验</span><br>
                <span class="about-describe-span2">{{about.implementationExperience}}</span>
                <span class="about-describe-span">年 </span>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div class="about-item">
                <img src="../assets/img/home/patent-icon.png" alt="成都瑞特" class="about-icon">
                <span class="about-describe-span">专利和著作权</span><br>
                <span class="about-describe-span2">{{about.patentsCopyrights}} </span>
                <span class="about-describe-span">
                  项
                </span>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div class="about-item" style="border:none">
                <img src="../assets/img/home/customer-icon.png" alt="成都瑞特" class="about-icon">
                <span class="about-describe-span">长期合作客户</span><br>
                <span class="about-describe-span2">{{about.customer}} </span>
                <span class="about-describe-span"></span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <!-- 新闻中心 -->
    <div class="news-content">
      <div class="title-content">
        <p class="title-english">NEWS CENTER</p>
        <p class="title-chinese">新闻中心</p>
      </div>
      <div class="product-tabs">
        <el-tabs v-model="newActiveName" class="demo-tabs" @tab-click="newHandleClick">
          <el-tab-pane :label="tab.name" :name="tab.id" v-for="tab in news" :key="tab.id">
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="card-box">
        <div class="partner-content">
          <div class="left"></div>
          <div class="center">
            <el-row :gutter="20">
              <el-col :span="8" :xs="24" v-for="newItem in newsCurData.slice(0,3)" :key="newItem.id">
                <div class="card-content" @click="toNewDetail('/newdetail',newItem.id,5)">
                  <div class="card-content-img-box">
                    <img :src="newItem.img" :alt="newItem.id" class="card-content-img">
                  </div>
                  <div class="card-content-titel-box">
                    <span>{{newItem.title}}</span>
                  </div>
                  <div class="card-content-desc-box">
                    <span>{{newItem.profile}}</span>
                  </div>
                  <div class="card-content-footer">
                    <span class="card-content-footer-date">{{newItem.createTime.slice(0,4)}}</span> <br>
                    <span class="card-content-footer-year">{{newItem.createTime.slice(5,10)}}</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="right"></div>
        </div>
      </div>
      <div class="product-more">
        <div class="more-button" @click="seeMore('/new',5)">
          <span>查看更多</span>
          <i class="el-icon-right" style="color:red;font-size: 16px;"></i>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div style="margin:40px 0">
      <div class="title-content">
        <p class="title-english">COOPERPERATIVE PARTNER</p>
        <p class="title-chinese">合作伙伴</p>
      </div>
    </div>
    <div class="partner-content">
      <div class="left"></div>
      <div class="center">
        <el-row :gutter="20">
          <el-col :span="4" :xs="8" v-for=" p in cooperation " :key="p.id">
            <div class="partner-content-item">
              <img :src="p.img" alt="暂无图片">
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
  import * as AES from '../utils/AES.js';
  import { reactive, toRefs, onMounted, ref } from 'vue';
  import * as http from '../axios/home'
  import { useRouter, onBeforeRouteUpdate } from
    'vue-router';
  import { routerActive } from '../store/index'
  export default {
    metaInfo: {
      title: '智慧燃气-智能仓储-非标自动化-数字化工厂-成都瑞特数字科技',
      meta: [   // set meta
        {
          name: 'keyWords',
          content: '智慧燃气,智能仓储,非标自动化,数字化工厂'
        },
        {
          name: 'description',
          content: '成都瑞特数字科技有限责任公司利用数字化,自动化,网络化和智能化为特征的现代新型技术,致力于智慧燃气,智能仓储,非标自动化,数字化工厂等业务,一直为全球500强企业Emerson Fisher等公司提供专业,优质的服务.'
        }
      ]
    },
    setup() {
      const router = useRouter();
      const routerActives = routerActive();
      onBeforeRouteUpdate((to) => {
        console.log(to.path, '路由监听');
      });
      const homeData = reactive({
        bannerHeight: '600px', // 轮播图高度
        activeName: 'a',
        productActiveName: '', // 产品中心当前tab
        newActiveName: '', // 新闻中心当前tab
        carousel: [], // 轮播图
        programme: [], //解决方案
        about: [],//关于我们
        product: [],//产品中心
        news: [],//新闻中心
        cooperation: [],
        productCurData: [], // 产品中心当前数据
        newsCurData: [] // 新闻中心当前数据
      });
      const handleClick = (props) => {
        homeData.activeName = props.props.name;
      };
      /**
       * @describe 获取首页数据
       */
      onMounted(async () => {
        let res = await http.homeAllData();
        const { data, code, msg } = res;
        if (code === 200) {
          homeData.carousel = data.filter(i => i.name === '轮播图')[0].data;
          homeData.programme = data.filter(i => i.name === '解决方案')[0].data;
          homeData.about = data.filter(i => i.name === '关于我们')[0].data;
          homeData.cooperation = data.filter(i => i.name === '合作伙伴')[0].data;
          homeData.product = data.filter(i => i.name === '产品中心')[0].data;
          homeData.news = data.filter(i => i.name === '新闻中心')[0].data;
          if (homeData.product.length) {
            homeData.productActiveName = homeData.product[0].id;
            homeData.productCurData = homeData.product[0].webProducts;
          }
          if (homeData.news.length) {
            homeData.newActiveName = homeData.news[0].id;
            homeData.newsCurData = homeData.news[0].news;
          }
        } else {
          console.log(msg)
        }
      });
      // 产品中心tab栏点击事件
      const productHandleClick = (tab) => {
        // 根据当前tab name值进行数据切换
        homeData.productCurData = homeData.product.filter(i => (i.id === tab.props.name))[0].webProducts;
      };
      // 新闻中心tab栏点击事件
      const newHandleClick = (tab) => {
        // 根据当前tab name值进行数据切换
        homeData.newsCurData = homeData.news.filter(n => (n.id === tab.props.name))[0].news;
      }
      //查看更多
      const seeMore = (path, active, type) => {
        routerActives.active = active;
        sessionStorage.setItem("active", active);
        router.push({
          path: path,
          query: {
            type: type || undefined
          }
        });
      };
      // 轮播图跳转
      const carouselTo = (path, active) => {
        let _type = path.charAt(path.length - 1);
        routerActives.active = active;
        sessionStorage.setItem("active", active);
        router.push({
          path: path,
          query: {
            type: _type
          }
        });
      };
      const screenWidth = ref();
      // 获取屏幕宽度
      onMounted(() => {
        screenWidth.value = document.body.clientWidth;
        window.onresize = () => {
          return (() => {
            screenWidth.value = document.body.clientWidth
          })()
        }
      });
      // 跳转产品中心详情页
      const toProductDetail = (path, id ,active) => {
        routerActives.active = active;
        sessionStorage.setItem("active", active);
        router.push({
          path: path,
          query: {
            id: AES.encrypt(id)
          }
        })
      };
      // 跳转新闻详情页
      const toNewDetail = (path, id, active) => {
        routerActives.active = active;
        sessionStorage.setItem("active", active);
        router.push({
          path: path,
          query: {
            id: AES.encrypt(id)
          }
        })
      }
      return {
        ...toRefs(homeData),
        // currentAnctive,
        handleClick,
        productHandleClick,
        newHandleClick,
        seeMore,
        toProductDetail,
        toNewDetail,
        carouselTo
      }
    }

  }
</script>

<style scoped>
  .banar {
    width: 100%;
    height: auto;
    margin: 20px 0 40px 0;

  }

  .banar img {
    width: 100%;
    height: 100%;
  }

  .solution-zz {
    width: 100%;
    height: 340px;
    margin-top: 64px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    position: relative;
    overflow: hidden;
  }
  @media screen and (max-width: 425px) {
    .solution-zz {
      height: 265px;
    }
  }

  .solution-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all .3s ease-in-out;
  }
  .solution-image:hover{
    width: 110%;
    height: 110%;
  }

  .about-item {
    width: 100%;
    height: 58px;
    border-right: 1px solid #b0b2bf;
    position: relative;
    color: #0051af;
    text-align: center;
    margin: 10px 0;
  }

  .about-describe-span {
    font-size: 14px;
  }

  .about-describe-span2 {
    font-size: 36px;
  }

  .about-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 5%;
  }

  @media screen and (max-width: 425px) {
    .about-icon {
      width: 30px;
      height: 30px;
    }

    .about-describe-span2 {
      font-size: 14px;
      font-weight: 600;
    }

    .about-describe-span {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 768px) {
    .about-describe-span2 {
      font-size: 28px;
    }
  }

  .about-bg {
    width: 100%;
    height: 340px;
    margin-top: 64px;
    background-image: url('../assets/img/home/about.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .wenhua-bg {
    width: 100%;
    height: 340px;
    margin-top: 64px;
    background-image: url('../assets/img/home/wenhua.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .zizhi-bg {
    width: 100%;
    height: 340px;
    margin-top: 64px;
    background-image: url('../assets/img/home/zizhi.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .about-content {
    width: 100%;
    height: 340px;
    margin-top: 64px;
  }

  .about-content-tabs {
    width: 100%;
    min-height: 260px;
  }

  .about-content-tabs-span {
    display: inline-block;
    line-height: 30px;
  }

  .about-content-foot {
    width: 100%;
    height: 90px;
    line-height: 90px;
  }

  .about-content-btn-div {
    display: inline-block;
    width: 144px;
    height: 40px;
    background: #108bed;
    color: #fff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }

  .solution-describe {
    font-size: 26px;
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 32px;
  }

  @media screen and (max-width: 768px) {
    .solution-describe {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 425px) {
    .solution-describe {
      font-size: 18px;
    }
  }

  .product-content {
    width: 100%;
    min-height: 826px;
    margin: 8% 0 5% 0;
    background-image: url('../assets/img/home/product.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 80px;
  }

  .product-card-img-box {
    width: 100%;
    height: 264px;
  }

  .product-card-footer {
    width: 100%;
    height: 79px;
    font-size: 16px;
    color: #108bed;
    line-height: 79px;
    cursor: pointer;
  }

  .news-content {
    width: 100%;
    min-height: 826px;
    margin: 8% 0 5% 0;
    background-image: url('../assets/img/home/news.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 80px;
  }

  .product-tabs {
    width: 100%;
    height: 60px;
    margin-top: 60px;
    /* text-align: center; */
    display: flex;
    justify-content: center;
  }

  .card-box {
    width: 100%;
    margin-top: 5%;
  }

  .product-more {
    width: 100%;
    height: 144px;
    text-align: center;
    line-height: 144px;
    margin-top: 60px;
  }

  .more-button {
    display: inline-block;
    width: 144px;
    height: 40px;
    color: #108bed;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    border: 1px solid #108bed;
    border-radius: 20px;
    cursor: pointer;
  }

  .tab-span {
    display: inline-block;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin: 10px 5px;
    cursor: pointer;
  }

  .tab-span-active {
    color: #108bed;
  }

  .partner-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 400px;
  }

  .partner-content-item {
    width: 100%;
    height: auto;
    border: 1px solid #dde2e6;
    text-align: center;
    margin-bottom: 20px;
  }

  .partner-content-item img {
    width: 80%;
    height: 80%;
  }

  .partner-content .left {
    width: 15%;
  }

  .partner-content .center {
    height: 100%;
    flex: 1;
  }

  .partner-content .right {
    width: 15%;
  }

  @media screen and (max-width: 425px) {
    .partner-content .left {
      width: 5%;
    }

    .partner-content .right {
      width: 5%;
    }
  }

  @media screen and (max-width: 1024px) {
    .partner-content .left {
      width: 5%;
    }

    .partner-content .right {
      width: 5%;
    }
  }

  @media screen and (max-width: 1440px) {
    .partner-content .left {
      width: 10%;
    }

    .partner-content .right {
      width: 10%;
    }
  }

  .public-main {
    width: 100%;
    height: 349px;
    border: 1px solid #dde2e6;
    display: flex;
  }

  .card-content {
    width: calc(100 - 40px);
    height: 400px;
    margin: 10px 0;
    padding: 20px 20px;
    background: #fcfcfc;
    cursor: pointer;
  }

  .card-content:hover {
    box-shadow: 4px 4px 15px #666;
  }

  .card-content-img-box {
    width: 100%;
    height: 180px;
  }

  .card-content-img {
    width: 100%;
    height: 100%;
  }

  .card-content-titel-box {
    width: 100%;
    height: 55px;
    color: #333333;
    font-size: 18px;
    font-weight: 600;
    padding-top: 20px;
  }

  .card-content-desc-box {
    width: 100%;
    height: 60px;
    color: #666874;
    font-size: 14px;
    padding-top: 20px;
    overflow: hidden;
  }

  .card-content-footer {
    width: 100%;
    height: 60px;
  }

  .card-content-footer-date {
    font-size: 31px;
    color: #b0b2bf;
  }

  .card-content-footer-year {
    font-size: 14px;
    color: #b0b2bf;
  }
</style>