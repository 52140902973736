/*
 * @Author: 李劲宏
 * @Date: 2022-11-03 16:26:46
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-11 14:32:28
 * @FilePath: /officialwebsite-1/src/axios/index.js
 */
// 生产环境地址：http://39.108.141.146:8092
import axios from 'axios'
import * as AES from '../utils/AES'
const BASE_URL = 'http://39.108.141.146:8092';
// 创建一个 axios 实例
const service = axios.create({
	baseURL: BASE_URL, // 生产环境
	timeout: 60000, // 请求超时时间毫秒
	withCredentials: true, // 异步请求携带cookie
	headers: {
		// 设置后端需要的传参类型
		'Content-Type': 'application/json',
	},
})

// 添加请求拦截器
service.interceptors.request.use(
	function (config) {
		// 在发送请求之前做些什么
		config.data = AES.encrypt(JSON.stringify(config.data))
		return config
	},
	function (error) {
		// 对请求错误做些什么
		console.log(error)
		return Promise.reject(error)
	}
)

// 添加响应拦截器
service.interceptors.response.use(
	function (response) {
		let dataAxios = null;
		if(response.data){
			dataAxios = JSON.parse(AES.decrypt(response.data));
		}
		return dataAxios
	},
	function (error) {
		
		return Promise.reject(error)
	}
)

export default service