/*
 * @Author: 李劲宏
 * @Date: 2022-11-04 11:31:35
 * @LastEditors: 李劲宏
 * @LastEditTime: 2022-11-08 09:19:12
 * @FilePath: /官网项目/src/axios/new.js
 */
import httpRequest from './index.js'
//新闻类型查询
export const newTypeList = () => {
    return httpRequest({
        url: '/companyserver/api/news/categoryList',
        method: 'post',
    })
}
//新闻分页查询
export const newPageList = (params) => {
    return httpRequest({
        url: '/companyserver/api/news/queryPage',
        method: 'post',
        data: params
    })
}
// 新闻详情
export const newDetailsData = (params) => {
    return httpRequest({
        url: '/companyserver/api/news/queryDetail',
        method: 'post',
        data: params
    })
}
